.content_image {
    &_wrapper {
        max-width: rem(1110);
        display: flex;
        justify-content: center;
        margin: auto;
        overflow: hidden;

        img {
            border-radius: rem(50) rem(50) 0 0;
        }
    }
}
