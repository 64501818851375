.primary-menu {
  display: none;
}

@include above($desktop-sm) {
  .primary-menu {
    display: flex;

    > li + li {
      margin-left: rem(30);
    }

    > li {
      position: relative;
      padding: rem(10) 0;

      &.menu-item-has-children {
        position: relative;
        padding-right: rem(16);

        > a {
          &:after {
            @include size(rem(6));
            content: "";
            display: block;
            border: 1px solid currentColor;
            border-width: 0 0 1px 1px;
            position: absolute;
            top: rem(17);
            right: 0;
            transform: rotate(-45deg);
          }
        }
      }

      &:hover {
        .primary-menu-sub {
          display: block;
        }
      }

      &.submenu-columns:hover {
        .primary-menu-sub {
          display: flex;
        }
      }
    }

    a {
      font-size: rem(13);
      font-weight: 600;
      text-decoration: none;
    }

    .current_page_ancestor,
    .current_page_item,
    .current-menu-item {
      > a {
        text-decoration: underline;
      }
    }
  }

  .primary-menu-sub {
    display: none;
    margin-top: 0;
    z-index: 99;
    position: absolute;
    background-color: $white;
    padding: rem(30) rem(30) rem(40);
    margin-left: rem(-30);
    border-radius: 0 0 rem(20) rem(20);
    box-shadow: 0 rem(10) rem(10) rgba($black, 0.05);

    a {
      font-size: rem(15);
      line-height: 1.4;
      display: block;
      color: $text-color;
      padding: 0.3em 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .submenu-columns {
    .primary-menu-sub {
      width: rem(350);
      justify-content: space-between;

      > li {
        width: calc(50% - #{rem(10)});

        > a {
          color: $main-blue;
        }

        > ul {
          margin-top: rem(10);
        }
      }
    }
  }
}
