.tease-search {
  & + & {
    margin-top: rem(30);
  }

  mark {
    background-color: rgba($light-blue, .5);
  }

  &__inner {
    display: grid;
    grid-template-columns: 1fr rem(100);
    gap: rem(20);
    align-items: center;

    @include above($tablet) {
      grid-template-columns: 1fr rem(200);
    }
  }

  &__title {
    font-size: rem(18);
    line-height: 1.5;
    margin: 0;
    font-weight: 700;

    a,
    a:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  &__body {
    width: 100%
  }

  &__excerpt {
    p {
      margin: 0;
    }
  }

  .link {
    text-transform: uppercase;
    font-weight: 700;
    font-size: rem(13);
  }

  &__link {
    margin-top: rem(5);
  }

  &__visual {
    margin: 0;
    width: rem(100);
    height: rem(70);
    border-radius: rem(20) rem(20) 0 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.tease-search--products {
  .tease-search__visual {
    border-radius: 0;
    width: rem(100);
    height: rem(100);

    img {
      object-fit: contain;
    }
  }
}

@include above($tablet) {
  .tease-search {
    min-height: rem(115);

    &__visual {
      width: rem(200);
      height: rem(115);
      border-radius: rem(40) rem(40) 0 0;
    }
  }

  .tease-search--products {
    .tease-search__visual {
      width: rem(200);
      height: rem(115);
    }
  }
}
