.home-hero {
  position: relative;
  padding-top: rem(50);

  .constrict {
    padding-bottom: 0;
    max-width: rem(1400);
  }

  &__titles {
    color: $white;
    margin-bottom: rem(20);
  }

  .subtitle *, .title {
    color: inherit;
  }

  &__background {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: rem(100);
    left: 0;
    background: linear-gradient(90deg, $main-blue, $mid-blue);

    &::before {
      position: absolute;
      top: 0;
      right: 61.5%;
      content: '';
      display: block;
      width: rem(786);
      height: rem(499);
      background-image: url('./static/img/pattern.png');
      z-index: 1;
      opacity: .4;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      mix-blend-mode: darken;
    }
  }

  &__inner {
    position: relative;
    z-index: 1;
  }

  .category {
    text-align: center;
    color: $main-blue;
    border-radius: rem(50);
    overflow: hidden;
    background: linear-gradient(255deg, $light-blue, $lighter-blue);
    display: flex;
    height: rem(150);
    margin: rem(15) 0;

    &__text {
      display: none;
    }

    &__link {
      font-family: "Stratos", sans-serif;
      text-transform: uppercase;
      font-weight: bold;
      font-size: rem(13);
    }

    &__image {
      width: 40%;
      min-width: rem(150);

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      padding: auto rem(10);

      h3 {
        margin: 0;
        margin-bottom: rem(5);
      }
    }

  }
}




@include above($tablet) {
  .home-hero {
    &__background {
      bottom: rem(110);
    }

    &__categories {
      display: flex;
      align-items: flex-end;
      margin: 0 rem(-15);
    }

    .category {
      height: rem(365);
      margin: 0 rem(15);

      flex-direction: column;


      &__image {
        width: 100%;
        min-width: 100%;
        height: calc(100% - #{rem(110)})
      }

      &__content {
        min-height: rem(110);

      }
    }
  }


}


@include above($desktop-sm) {
  .home-hero {

    &__titles {
      position: absolute;
      right: 0;
      top: rem(20);
      left: 38%;
      height: rem(200);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .category {
      flex: 0 1 rem(277);
    }

    .category--main {
      flex: 1 1 rem(426);
      height: rem(615);

      .category__text {
        display: block;
        margin: rem(10) 0 rem(20);
      }

      .category__image {
        height: 100%;
        flex: 1 1 auto;
      }

      .category__content {
        min-height: unset;
        padding: rem(40) rem(30) rem(30);
      }
    }
  }


}
