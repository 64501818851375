.wave-list {
  list-style: none;
  font-size: rem(15);
  line-height: 2;
  padding: 0;

  li {
    padding-left: rem(33);
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: .7em;
      left: 0;
      width: rem(28);
      height: rem(21);
      background-image: url('./static/img/wave.svg');
      background-repeat: no-repeat;
    }
  }
}
