.partners {
    &_wrapper {
        margin-top: rem(76);

        .title {
            margin-bottom: rem(30);
            text-align: center;
        }
    }

    &_collection {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: rem(880);
        margin: auto;

        .slick-arrow {
            margin: rem(20);
        }
        a {
            margin: 0 rem(15);
            text-decoration: none;
        }
    }
}
