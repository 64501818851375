.pagination {
  text-align: center;
  margin-top: rem(40);
  margin-bottom: rem(40);

  li {
    width: rem(25);
    height: rem(40);
    display: inline-block;
    vertical-align: middle;
    margin: 0 rem(5);

    &.first,
    &.last,
    &.prev,
    &.next {
      margin: 0;
      width: rem(25);
    }

    > a, > span {
      @include size(100%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(14);
      text-decoration: none;
      color: $text-color;
    }

    > a:hover {
      color: $main-blue;
    }

    &.current {
      > a, > span {
        color: $main-blue;
        font-weight: 700;
      }
    }

    svg {
      path {
        fill: $main-blue;
      }
    }

    &.disabled {
      opacity: .4;
    }
  }
}
