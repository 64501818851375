.products-intro {
  position: sticky;
  top: 0;
  background-color: $light-blue;
  border-radius: 0 rem(50) 0 0;
  padding: rem(30);
  display: flex;
  align-items: center;
  margin-top: rem(20);

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    display: block;
    width: 100vw;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: inherit;
    border-radius: inherit;
  }

  &__title {
    margin-bottom: .5em;
    &.underline {
      padding-bottom: .7em;
    }
  }
  &__inner {

  }
}


@include above($tablet) {
  .products-intro {
    padding: rem(50);
    min-height: rem(400);

    &.has-negative-margin {
      margin-top: rem(-50);
    }
  }
}
