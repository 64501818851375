.input-field {
  width: 100%;
  margin-bottom: rem(25);

  label {
    @include visually-hidden;
  }
}


@include above($tablet) {
  .fields-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .input-field {
    &--half {
      width: calc(50% - #{rem(10)});
    }
  }
}
