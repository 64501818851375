%btn {
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: rem(2);
  font-size: rem(13);
  padding: rem(17) rem(20);
  min-width: rem(216);
  background: linear-gradient(90deg, #012784, #014cbc);
  color: $white;
  border-radius: rem(15);
  position: relative;
  z-index: 2;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;

  &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(90deg, #4c90f9, #0458f4);
      z-index: -1;
      transition: opacity 200ms linear;
      opacity: 0;
      border-radius: rem(15);
  }

  &:hover {
      color: $white;
      &::before {
          opacity: 1;
      }
  }
}

%btn-outlined {
  background: transparent;
  color: $main-blue;
  border: rem(1) solid $main-blue;

  &::before {
      top: rem(-1);
      right: rem(-1);
      bottom: rem(-1);
      left: rem(-1);

      // border: rem(1) solid $main-blue;
  }
}


div.btn {
  a {
    @extend %btn;
  }

  &-outlined {
    a {
      @extend %btn-outlined;
    }
  }
}

a, button, input[type="submit"] {
  &.btn {
    @extend %btn;
  }

  &.btn-outlined {
    @extend %btn-outlined;
  }
}
