.wpcf7 {
  width: 100%;

  p {
    margin: 0;
  }

  .wpcf7-not-valid-tip {
    font-size: rem(14);
    margin-top: rem(10);
    padding-left: rem(5);
  }

  .wpcf7-form-control-wrap {
    display: block;
    width: 100%;
    flex: 1;
    margin-right: rem(20);
    position: relative;

    input {
      width: 100%;
      display: block;
    }

  }

  form.invalid {
    .wpcf7-response-output {
      display: none;
    }
  }

}
