.collection-header {
  padding: rem(60) rem(30) rem(30);
  text-align: center;
  color: $white;
  background: $main-blue linear-gradient(90deg, $main-blue, $mid-blue);
  border-radius: 0 0 rem(50) rem(50);
  position: relative;
  overflow: hidden;

  @include above($tablet) {
    padding: rem(60) rem(60) rem(80);
  }

  .breadcrumbs {
    position: absolute;
    top: 0;
    z-index: 10;

    a {
      color: white;
    }
  }

  &__visual {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    opacity: 0.13;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__inner {
    max-width: rem(1110);
    margin: auto;
    position: relative;
    z-index: 1;
  }

  &__title.h2 {
    color: inherit;
    margin-top: 0;
    margin-bottom: 1em;

    &:after {
      background: currentColor;
    }
  }
  &__intro {
    font-size: rem(14);
    line-height: 1.6;

    @include above($tablet) {
      font-size: rem(15);
      line-height: 1.8;
    }
  }
}
