.text_with_image {
    overflow: hidden;
    margin-bottom: rem(30);

    & + .product_advantages,
    & + .posts {
        margin-top: rem(-30);
    }

    &.pattern {
        .text_with_image_wrapper {
            grid-template-columns: 30% 70%;
            position: relative;
            padding: 0 rem(30);

            &::after {
                content: "";
                display: block;
                height: 100%;
                width: 100%;
                position: absolute;
                left: 100%;
                top: 0;
                background: url("./static/img/pattern.png");
                background-repeat: no-repeat;
            }
        }

        &.image-right {
            .text_with_image_wrapper {
                grid-template-columns: 70% 30%;
                position: relative;
                &::after {
                    content: "";
                    display: block;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    right: 100%;
                    left: unset;
                    top: 0;
                    background: url("./static/img/pattern.png");
                    background-repeat: no-repeat;
                    background-position: right;
                }
            }
        }
    }

    &.image-right {
        .text_with_image_wrapper {
            .image {
                img {
                    right: unset;
                    left: 0;
                }
            }

            .content {
                grid-column: 1;
                grid-row: 1;
                justify-self: start;
                padding-right: rem(100);
                padding-left: 0;
            }
        }
    }

    .constrict {
        padding-bottom: 0;
    }
    &_wrapper {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        align-items: center;
        grid-gap: rem(30);
        max-width: rem(1110);
        margin: auto;
        min-height: rem(400);

        .image {
            width: 100%;
            height: 100%;
            position: relative;

            img {
                border-radius: rem(50) rem(50) 0 0;
                width: auto;
                height: 100%;
                display: block;
                position: absolute;
                right: 0;
            }
        }

        .content {
            margin-top: rem(40);
            margin-bottom: rem(40);
            padding-left: rem(100);

            .btn {
                margin-top: rem(29);
            }
        }
    }
}

@include below($desktop-sm) {
    .text_with_image {
        &.pattern {
            background: none;

            .text_with_image_wrapper {
                &::after {
                    display: none;
                }
            }

            &.image-right {
                .text_with_image_wrapper {
                    &::after {
                        display: none;
                    }
                }
                .image {
                    margin-right: 0;
                }
            }

            .image {
                margin-left: 0;
            }
        }

        &.image-right {
            .text_with_image_wrapper {
                .content {
                    margin-right: 0;
                    margin-left: 0;
                    padding-right: 0;
                    padding-left: 0;
                    margin-top: rem(30);
                    max-width: unset;
                }
            }
        }

        &_wrapper {
            display: block;
            min-height: unset;

            .image {
                margin-left: 0;

                img {
                    position: inherit;
                    width: 100%;
                }
            }

            .content {
                margin-right: 0;
                margin-left: 0;
                padding-right: 0;
                padding-left: 0;
                margin-top: rem(30);
                max-width: unset;
            }
        }
    }
}
