.subscribe-field {
  position: relative;
  width: 100%;

  .subscribe-email {
    position: relative;
    margin: 0;

    input {
      text-align: center;
      border: none;
    }
  }

  .wpcf7-submit {
    @extend %btn;
    width: 100%;
    min-width: 0;
    padding-left: 0;
    padding-right: 0;
    flex-shrink: 0;
    margin-top: rem(20);
    height: rem(45);
  }

  input {
    border: none;
  }
}


.wpcf7 form {
  .subscribe-field {
    .wpcf7-form-control-wrap {
      margin: 0;
    }

    .ajax-loader {
      display: none;
    }
  }

  &.submitting {
    .subscribe-field {
      .subscribe-email,
      .wpcf7-submit {
        opacity: .5;
      }

      .ajax-loader {
        display: block;

        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: 50%;
        left: 50%;
        margin-left: rem(-12);
        margin-top: rem(-12);
      }
    }
  }
}

@include above($tablet-xs) {
  .subscribe-field {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .subscribe-email {
      input {
        text-align: left;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .wpcf7-submit {
      width: rem(140);
      padding-left: 0;
      padding-right: 0;
      flex-shrink: 0;
      margin-top: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

@include between($tablet, $desktop) {
  .subscribe-field {
    display: block;

    .subscribe-email {
      input {
        text-align: center;
        border-radius: rem(15);
      }
    }

    .wpcf7-submit {
      width: 100%;
      margin-top: rem(20);
      border-radius: rem(15);
    }
  }

}
