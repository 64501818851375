.single {
  .constrict {
    max-width: rem(1110);
    margin: auto;
    padding-bottom: 0;
  }

  &__title {
    font-weight: 500;
  }

  .share {
    & > p {
      margin: 0;
    }

    &-top {
      position: absolute;
      left: rem(30);
    }

    &-bottom {
      display: none;
      margin-bottom: rem(20);
    }
  }

  .post-nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .prev-post,
    .next-post {
      color: $main-blue;
      font-weight: bold;
      font-size: rem(13);

      a {
        text-transform: uppercase;

        svg {
          width: rem(8);
          height: rem(8);
        }
      }
    }

    .prev-post {
      grid-column: 1;

      p {
        margin-left: rem(10);
      }
    }
    .next-post {
      grid-column: 2;
      justify-self: end;
      text-align: end;

      p {
        margin-right: rem(10);
      }
    }
  }
}

@include below(1300px) {
  .single {
    .share {
      &-top {
        display: none;
      }
      &-bottom {
        display: block;
      }
    }
  }
}
