a {
    color: $main-blue;
    transition: 200ms;

    svg {
        fill: currentColor;

        path {
            fill: currentColor;
        }
    }

    &:hover {
        color: $light-blue;
    }
}
