.search-bar {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: $white;
  z-index: 1000;
  display: none;

  &__inner {
    width: 100%;
    height: rem(60);
    display: flex;
    align-items: center;
    justify-content: center;

    .searchform {
      width: 100%;
    }
  }

  .search-close {
    position: absolute;
    right: rem(10);
    top: 50%;
    margin-top: rem(-20);
  }

  &.show {
    display: block;
  }
}

.admin-bar {
  .search-bar {
    top: 46px;

    @include above(783px) {
      top: 32px;
    }
  }
}


.searchform-field {
  border-radius: rem(15);
  border: 1px solid $main-blue;
  max-width: rem(610);
  margin-left: auto;
  margin-right: rem(30);
  display: flex;
  overflow: hidden;

  input {
    border: none;
    flex: 1;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: rem(18);
    padding-right: rem(18);
    height: rem(40);
    outline: none;
  }

  button {
    width: rem(60);
    height: rem(40);
    color: $main-blue;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    appearance: none;
    border: none;
    background-color: transparent;
    outline: none;

    svg {
      display: block;
      path {
        fill: currentColor;
      }
    }
  }

}


@include above($tablet) {
  .search-bar {
    .search-close {
      right: rem(30);
    }

    .searchform-field {
      margin-right: auto;
    }
  }
}
