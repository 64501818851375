.tease-table {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .table {
        text-align: center;

        tr:nth-of-type(odd) {
            background: $light-grey;
        }
        tr:nth-of-type(even) {
            background: $white;
        }

        th {
            background: $main-blue;
            padding: rem(20);
            color: $white;
        }

        td {
            padding: rem(10);
            min-width: rem(180);
        }
    }
}
