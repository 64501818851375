.products-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: rem(50);
  margin-top: rem(60);
  margin-bottom: rem(60);
}


@include above($tablet-xs) {
  .products-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include above($tablet) {
  .products-grid {
    grid-template-columns: repeat(3, 1fr);

    &.--md-col-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include above($desktop-md) {
  .products-grid {
    &.--lg-col-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.--lg-col-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
