.home_products {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        display: block;
        top: rem(-80);
        left: 0;
        bottom: 0;
        right: 0;
        background: url("./static/img/pattern.png");
        background-repeat: no-repeat;
        background-position: calc(100% + #{rem(600)}) rem(-290);
        z-index: -1;
        opacity: 0.5;
    }

    &_wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0 rem(31);
        padding-top: rem(300);

        .product {
            box-shadow: 0 10px 20px rgba(18, 31, 138, 0.15);
            border-radius: rem(50);
            text-align: center;
            padding: rem(147) rem(47) rem(47);
            position: relative;
            background: $white;
            color: $main-blue;

            .images {
                bottom: 70%;
                left: 0;
                position: absolute;
                height: rem(300);
                width: 100%;

                &:hover {
                    picture:first-child {
                        opacity: 0;
                    }
                    picture:last-child {
                        opacity: 1;
                    }
                }

                picture {
                    position: absolute;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    transition: 200ms;
                    left: 0;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }

                    &:last-child {
                        opacity: 0;
                    }
                }
            }
        }
    }
}

@include below($desktop-sm) {
    .home_products {
        &_wrapper {
            padding-top: rem(350);

            .product {
                .images {
                    width: 70%;
                    margin: 0 15%;
                }
            }
        }
    }
}

@include below($tablet) {
    .home_products {
        &_wrapper {
            padding-top: rem(180);
            grid-template-columns: 1fr;
            grid-gap: rem(20);

            .product {
                padding: rem(30) rem(20);
                .images {
                    position: relative;
                    height: rem(200);
                    width: 100%;
                    bottom: unset;
                    margin: 0;
                    margin-bottom: rem(20);

                    img {
                        object-fit: contain;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        bottom: unset;
                    }
                }
            }
        }
    }
}
