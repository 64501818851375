.error-page {
  margin: rem(50) 0;

  &__visual {
    margin: 0;

    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  &__content {
    text-align: center;
    max-width: rem(400);
    margin: rem(30) auto 0;

    .btn {
      width: 100%;
    }
  }

  h2, p {
    margin: rem(20) 0;
  }
}

@include above($mobile) {
  .error-page {
    &__content {
      .btn {
        width: auto;
      }
    }
  }
}

@include above($desktop-sm) {
  .error-page {
    &__inner {
      display: flex;
      align-items: center;
      margin-top: rem(120)
    }

    &__content {
      margin-top: 0;
      flex: 0 0 rem(460);
      max-width: 100%;
      padding: 0 rem(40)
    }

    h2, p {
      margin: rem(40) 0;
    }
  }


}
