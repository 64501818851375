.cta_block {
    &.boxed .cta_block_wrapper {
        background: linear-gradient(90deg, $lighter-blue, $light-blue);
        border-radius: rem(50);
        max-width: rem(1111);
        position: relative;
        padding: rem(35) rem(80) rem(35);

        .wysiwyg {
            & > * {
                color: $main-blue;
            }
        }

        &::before {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: url(./static/img/pattern.png);
            border-radius: rem(50);
            mix-blend-mode: screen;
            opacity: 0.5;
        }
    }

    &:not(.boxed) {
        position: relative;
        margin-bottom: rem(30);

        &::before {
            display: block;
            content: "";
            position: absolute;
            left: rem(-500);
            top: 0;
            height: 100%;
            width: 75%;
            background: url(./static/img/pattern.png);
            background-repeat: no-repeat;
            opacity: 0.5;
        }
    }

    &_wrapper {
        text-align: center;
        padding: rem(35) rem(80) 0;
        max-width: rem(846);
        margin: auto;

        .wysiwyg {
            margin-bottom: rem(30);
        }

        .buttons {
            display: flex;
            justify-content: center;

            .btn {
                margin: 0 rem(30);
            }
        }
    }
}

@include below($desktop-md) {
    .cta_block {
        &:not(.boxed) {
            &::before {
                width: 60%;
            }
        }
    }
}

@include below($tablet) {
    .cta_block {
        &.boxed {
            .cta_block_wrapper {
                padding: rem(30) rem(20) rem(30);
            }
        }
        &_wrapper {
            padding: rem(30) 0 0;

            .buttons {
                flex-direction: column;
                .btn {
                    margin: 0;
                    &:first-child {
                        margin-bottom: rem(10);
                    }
                }
            }
        }
    }
} ;
