.image_gallery {
  &_wrapper {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(2, 50%);
    height: 50vh;
    margin-bottom: rem(50);

    picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:nth-child(1) {
        grid-column: span 2;
        grid-row: span 2;
      }
      &:nth-child(3) {
        grid-row: span 2;
      }
    }
  }
}

@include below($tablet) {
  .image_gallery {
    &_wrapper {
      grid-template-columns: repeat(2, 50%);

      picture {
        &:nth-child(1) {
          grid-column: 1;
          grid-row: 1;
        }
        &:nth-child(3) {
          grid-row: 2;
        }
      }
    }
  }
}
