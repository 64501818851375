.site-header {
  position: relative;
  z-index: 999;
  height: rem(60);

  &__logo {
    height: rem(45);
  }

  &__inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__secondary {
    display: flex;
    align-items: center;

    * + * {
      margin-left: rem(10);
    }

    .btn {
      display: none;
    }
  }
}

@include above($desktop-sm) {
  .site-header {
    &__secondary {
      .btn {
        min-width: 0;
        display: inline-block;
      }

      .nav-toggle {
        display: none;
      }
    }
  }
}
