.posts {
  padding-top: rem(43);
  &.background {
    background: linear-gradient(90deg, $main-blue, $mid-blue);
    border-radius: 0 0 rem(50) rem(50);

    .title {
      color: $white;
      &::after {
        background: $white;
      }
    }

    .posts-grid {
      article,
      h4,
      a {
        color: $white;
      }
    }
  }

  &_wrapper {
    .title {
      margin-bottom: rem(50);
    }

    .posts-grid {
      display: grid;
      grid-auto-flow: column;
      grid-gap: rem(30);
      grid-auto-columns: 1fr;

      &.latest_two {
        article:not(.tease-products) {
          .category {
            picture {
              height: rem(350);
            }
          }
        }
      }

      article:not(.tease-products):not(.tease-table) {
        display: grid;
        grid-template-rows: rem(300) 1fr;
        text-decoration: none;
        position: relative;
        margin: rem(15);

        .category {
          position: absolute;
          padding: rem(13) rem(32);
          top: rem(-20);
          left: 10%;
          background: linear-gradient(90deg, $lighter-blue, $light-blue);
          border-radius: 0 rem(10) 0 rem(10);
          margin: 0;
          line-height: lh(18, 15);
          color: $main-blue;
          z-index: 1;
        }

        picture {
          overflow: hidden;
          border-radius: rem(50) rem(50) 0 0;
          height: rem(300);

          img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
          }
        }

        .content {
          margin-top: rem(10);
          display: flex;
          flex-direction: column;
          justify-content: s;

          h4 {
            font-weight: bold;
            margin-top: rem(10);
          }

          a {
            text-decoration: underline;
            text-transform: uppercase;
            font-weight: bold;
            font-size: rem(13);
          }
        }
      }
    }
  }
}

@include below($tablet) {
  .posts {
    &_wrapper {
      .posts-grid {
        grid-template-columns: 1fr;
        grid-auto-flow: row;

        &.latest_three,
        &.latest_two,
        &.custom {
          grid-template-columns: 1fr;
          grid-auto-flow: row;
        }
      }
    }
  }
}
