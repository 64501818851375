.nav-social {
  margin-top: rem(-10);

  ul {
    li {
      display: inline-block;
      vertical-align: bottom;
    }

    li + li {
      margin-top: 0;
      margin-left: rem(10);
    }
  }

  svg {
    path{
      fill: currentColor;
    }
  }
}
