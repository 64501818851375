.blog,
.post-type-archive-cases {
    .title {
        text-align: center;
        font-size: rem(50);
        line-height: lh(95, 50);
        margin-bottom: rem(10);
    }
    .posts-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: rem(95) rem(30);

        article {
            display: grid;
            grid-template-rows: rem(250) 1fr;
            text-decoration: none;
            position: relative;

            .category {
                position: absolute;
                padding: rem(13) rem(32);
                top: rem(-20);
                left: 10%;
                background: linear-gradient(90deg, $lighter-blue, $light-blue);
                border-radius: 0 rem(10) 0 rem(10);
                margin: 0;
                line-height: lh(18, 15);
                color: $main-blue;
                z-index: 1;
            }

            picture {
                overflow: hidden;
                border-radius: rem(50) rem(50) 0 0;
                height: rem(250);

                img {
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-top: rem(10);

                h4 {
                    font-weight: bold;
                    margin-top: rem(10);
                }

                a {
                    text-decoration: underline;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: rem(13);
                }
            }
        }
    }
}

@include below($tablet) {
    .blog,
    .post-type-archive-cases {
        .posts-grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@include below($tablet-xs) {
    .blog,
    .post-type-archive-cases {
        .posts-grid {
            grid-template-columns: 1fr;
        }
    }
}
