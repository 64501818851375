// ==========================================================================
// Fonts
// ==========================================================================
// Font-weights
// Thin (Hairline): 100
// Extra Light (Ultra Light): 200
// Light: 300
// Normal: 400
// Medium: 500
// Semi Bold (Demi Bold): 600
// Bold: 700
// Extra Bold (Ultra Bold): 800
// Black (Heavy): 900
// ==========================================================================

$base-font-path: "./static/fonts/";

$fonts: (
  "DM Sans": (
    (300, normal, "dm-sans/dmsans-light-webfont"),
    (300, italic, "dm-sans/dmsans-lightitalic-webfont"),
    (400, normal, "dm-sans/dmsans-regular-webfont"),
    (400, italic, "dm-sans/dmsans-italic-webfont"),
    (500, normal, "dm-sans/dmsans-medium-webfont"),
    (500, italic, "dm-sans/dmsans-mediumitalic-webfont"),
    (700, normal, "dm-sans/dmsans-bold-webfont"),
    (700, italic, "dm-sans/dmsans-bolditalic-webfont"),
    (800, normal, "dm-sans/dmsans-extrabold-webfont"),
    (800, italic, "dm-sans/dmsans-extrabolditalic-webfont"),
  ),
  "Nunito": (
    (200, normal, "nunito/nunito-extralight-webfont"),
    (200, italic, "nunito/nunito-extralightitalic-webfont"),
    (300, normal, "nunito/nunito-light-webfont"),
    (300, italic, "nunito/nunito-lightitalic-webfont"),
    (400, normal, "nunito/nunito-regular-webfont"),
    (400, italic, "nunito/nunito-italic-webfont"),
    (500, normal, "nunito/nunito-medium-webfont"),
    (500, italic, "nunito/nunito-mediumitalic-webfont"),
    (700, normal, "nunito/nunito-bold-webfont"),
    (700, italic, "nunito/nunito-bolditalic-webfont"),
    (800, normal, "nunito/nunito-extrabold-webfont"),
    (800, italic, "nunito/nunito-extrabolditalic-webfont"),
    (900, normal, "nunito/nunito-black-webfont"),
    (900, italic, "nunito/nunito-blackitalic-webfont"),
  ),
);

@if (variable-exists(fonts)) {
  @each $name, $properties in $fonts {
    @each $weight, $style, $path in $properties {
      @font-face {
        font-family: $name;
        font-weight: $weight;
        font-style: $style;
        src: url($base-font-path + $path + ".woff2") format("woff2"),
          url($base-font-path + $path + ".woff") format("woff");
      }
    }
  }
}
