.accordeon + .product-downloads {
  margin-top: rem(50);
}

.product-downloads {
  background: #f2f2f2;
  padding: rem(40) 0;

  &_wrapper {
    .download {
      display: flex;
    }
    .products {
      display: none;
      margin-top: rem(30);

      &_wrapper {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        align-items: center;
        grid-gap: 0 rem(70);
      }

      .product {
        &-images {
          justify-self: end;
        }

        &-select {
          display: flex;
          align-items: center;
          margin-bottom: rem(35);

          &_label {
            margin-right: rem(27);
          }

          .select {
            max-width: rem(250);

            &_wrapper {
              height: fit-content;
              max-width: rem(250);
            }
          }
        }

        &-files {
          .product-download {
            display: grid;
            grid-gap: rem(30) 0;
          }
          a {
            text-transform: uppercase;
            text-decoration: none;
            display: flex;
            align-items: center;

            &:hover {
              color: $main-blue;
              text-decoration: underline;
            }

            svg {
              flex-shrink: 0;
              width: rem(12);
              height: rem(12);
              margin-right: rem(5);
            }
          }
        }
      }
    }
  }
}

@include below($desktop-sm) {
  .product-downloads {
    &_wrapper {
      .products {
        &_wrapper {
          grid-template-columns: 1fr;
          justify-items: center;

          .product {
            &-images {
              display: none !important;
            }

            &-download-details {
              margin-top: rem(20);
            }
          }
        }
      }
    }
  }
}
