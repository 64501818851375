.content_block_two {
    &_wrapper {
        max-width: rem(1110);
        margin: auto;

        .title {
            margin-bottom: rem(20);

            &.center {
                text-align: center;
            }
        }

        .content-blocks {
            display: grid;
            grid-template-columns: repeat(2, 50%);
            grid-gap: rem(30);
        }
    }
}

@include below($tablet) {
    .content_block_two {
        &_wrapper {
            .content-blocks {
                grid-template-columns: 1fr;
            }
        }
    }
}
