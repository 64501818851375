#cookie-notice.cookie-notice-visible {
  background-color: $white !important;
  box-shadow: 0 rem(-20) rem(20) 0 rgba(18,31,138,0.15);
}

#cookie-notice.cn-position-bottom {
  display: none;

  &.cookie-notice-visible {
    display: block;
  }

  > .cookie-notice-container {
    padding-top: rem(40);
    padding-bottom: rem(40);
    width: 100%;
    max-width: rem(1050);
    margin: auto;
    color: $text-color !important;
  }

  .cn-text-container {
    width: 100%;
    max-width: rem(700);
    text-align: left;
    font-size: rem(15);
    margin: 0;

    h4 {
      margin-bottom: rem(10);
    }
  }

  .cn-buttons-container {
    text-align: left;
    margin-top: rem(20);

    .cn-button {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .cn-close-icon {
    top: rem(20);
  }

  #cn-close-notice {
    &::before,
    &::after {
      background-color: $main-blue !important;
    }
  }

  .cn-button {
    @extend %btn;
  }

}




@include above($tablet) {
  #cookie-notice.cn-position-bottom {
    > .cookie-notice-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
    }

    .cn-close-icon {
      top: 50%;
    }

    .cn-buttons-container {
      margin-top: 0;
    }
  }
}
