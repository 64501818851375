.breadcrumbs {
    margin-top: rem(37);

    font-size: rem(11);

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
