.footer {
  overflow: hidden;
  background: $main-blue linear-gradient(90deg, $main-blue, $mid-blue);
  color: white;
  position: relative;
  padding: rem(40) 0;

  a, h4, h3 {
    color: inherit;
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    width: rem(786);
    height: rem(499);
    background-image: url('./static/img/pattern.svg');
    z-index: 0;
  }

  &::before {
    // right: calc(50% + #{rem(160)});
    right: 61.5%;
  }

  &::after {
    // left: calc(50% + #{rem(130)});
    left: 59%;
    top: rem(-10);
  }

  &__column {
    margin-bottom: rem(40);
  }

  &__legal,
  &__inner {
    position: relative;
    z-index: 1;
  }

  &__legal {
    text-align: center;
  }

  .contact-list,
  .nav-footer {
    li + li {
      margin-top: rem(16);
    }
  }

  .contact-list {
    li.contact-list__item--email + li.contact-list__item--phone {
      margin-top: 0;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: rem(12);
    line-height: lh(18, 12);

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__title {
    margin: 0 0 rem(16);
  }

  &__legal {
    font-size: rem(12);
  }

  .nav-legal {
    display: inline-block;
    margin: 0 rem(15);

    li {
      display: inline-block;
      margin: 0 rem(15);
    }
  }
  .copyright, .nav-legal {
    margin: rem(10) 0 0;
  }
}

@include above($mobile) {
  .footer {
    &__inner {
      display: flex;
      flex-wrap: wrap;
    }

    &__column {
      flex: 1 0 50%;
      padding-right: rem(30);

      &--navigation {
        flex-basis: 100%;
      }

      // &--contact {
      //   flex-basis: 65%;
      // }

      // &--social {
      //   flex-basis: 35%;
      // }
    }

    .nav-footer {
      ul {
        columns: 2;
        column-gap: rem(30);
      }
    }
  }
}

@include above($tablet) {
  .footer {

    &__column {

      &--navigation {
        flex-basis: 45%;
      }

      &--contact {
        flex-basis: 30%;
      }

      &--social {
        flex-basis: 25%;
      }

    }
  }
}
