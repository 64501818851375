.contact {
  margin: rem(50) 0;

  &__title,
  &__intro,
  &__list {
    text-align: center;
  }

  &__intro,
  &__list,
  &__form {
    margin-top: rem(30);
  }
}

.contact-details-list {
  list-style: none;
  padding: 0;

  li {
    margin: rem(15) 0;
  }
}

.wpcf7 form.sent .wpcf7-response-output {
  border-radius: rem(30);
  padding: rem(22) rem(35);
  border-color: $light-blue !important;
}

@include above($tablet) {
  .contact {
    margin-bottom: rem(90);

    &__intro {
      margin-top: rem(20);
    }

    &__list {
      margin-top: rem(40);
    }

    &__form {
      margin-top: rem(60);
    }
  }
}
