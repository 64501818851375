// ==========================================================================
// Layout
// Layout rules divide the page into sections. Layouts hold one or more
// modules together.
// ==========================================================================

.constrict {
  max-width: rem(1340);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: rem(20);
  padding-right: rem(20);
  padding-bottom: rem(50);

  @include above($tablet) {
    padding-left: rem(30);
    padding-right: rem(30);
  }
}


.constrict-md {
  @extend .constrict;
  max-width: rem(1120);
  padding-bottom: 0;
}

.constrict-sm {
  @extend .constrict;
  max-width: rem(1000);
  padding-bottom: 0;
}

.constrict-xs {
  @extend .constrict;
  max-width: rem(885);
  padding-bottom: 0;
}

.constrict-lg {
  @extend .constrict;
  max-width: rem(1500);
  padding-bottom: 0;
}

.post-type-archive-products {
  .constrict {
    padding-bottom: 0;
  }
}

.slick-arrow {
  cursor: pointer;
}


body {
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  #content {
    flex: 1 0 auto;
  }

  .site-header,
  .footer {
    flex-shrink: 0;
  }
}
