.big_image {
    &_wrapper {
        height: rem(350);
        border-radius: 0 0 rem(50) rem(50);
        overflow: hidden;
        margin-bottom: rem(50);
        position: relative;

        picture {
          width: 100%;
          height: 100%;
          display: block;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .constrict {
            position: absolute;
        }
    }
}
