.tease-products {
  padding: rem(15);
  height: 100%;

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__visual {
    margin: 0;
    flex-shrink: 0;

    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: auto;
    }
  }

  &__title {
    flex: 1 0 auto;
    font-size: rem(15);
    color: $text-color;
    margin-top: rem(15);
    margin-bottom: rem(15);
    font-weight: 500;
    text-align: center;
  }

  &__cta {
    flex-shrink: 0;
  }

  div.btn a {
    max-width: 100%;
    display: block;
    min-width: 0;
  }
}
