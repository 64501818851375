.search-toggle {
  @include size(rem(40));
  border: none;
  background-color: transparent;
  display: flex;
  appearance: none;
  color: $main-blue;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  outline: none;

  svg {
    display: block;
    path {
      fill: currentColor;
    }
  }
}
