.post-type-products {
    .detail {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-gap: rem(30);

        .image {
            &-selected {
                width: 100%;
                max-height: rem(400);
                margin-bottom: rem(20);

                img {
                    width: 100%;
                    height: rem(400);
                    object-fit: contain;
                }
            }
            &-selector {
                display: flex;
                align-items: center;

                .slick-arrow {
                    margin: rem(10);
                }

                img {
                    width: rem(84) !important;
                    height: rem(84);
                    object-fit: contain;
                    cursor: pointer;
                    border: rem(1) solid transparent;
                    padding: rem(5);

                    &.active {
                        border: rem(1) solid $mid-blue;
                    }
                }
            }
        }

        &-content {
            display: grid;

            &-social {
                & > p {
                    margin: 0;
                }
            }
        }
    }

    .accordeon {
        &-title {
            display: grid;
            grid-template-columns: minmax(#{rem(200)}, auto) 2fr minmax(#{rem(30), auto});
            align-items: center;
            cursor: pointer;
            border-bottom: 1px solid $light-blue;

            svg {
                transition: 200ms;
            }

            &.active {
                svg {
                    transform: rotate(180deg);
                }

                .accordeon-content {
                    display: block;
                }
            }
        }

        &-content {
            grid-column: 2;
            grid-row: 2;
            margin-right: rem(50);
            margin-bottom: rem(30);
            display: none;
        }

        &-table .row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            padding: 0 rem(21);

            &:nth-of-type(odd) {
                background: $light-grey;
            }
        }
    }
}

@include below($desktop-sm) {
    .post-type-products {
        .detail {
            display: block;

            &-images {
                margin-bottom: rem(30);

                .image-selector {
                    margin: 0;
                }
            }
        }

        .accordeon {
            &-title {
                grid-template-columns: 1fr 2fr minmax(#{rem(30), auto});
                svg {
                    margin-left: rem(10);
                }
            }
            &-content {
                grid-column: span 3;
                margin-right: 0;
            }
        }
    }
}
