.toggle-submenu {
  @include size(rem(30));
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: $main-blue;
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: transparent;

  svg {
    path {
      fill: currentColor;
    }
  }
}
