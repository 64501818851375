.primary-menu-mobile {
  a {
    font-size: rem(18);
    line-height: lh(30, 18);
    text-decoration: none;
  }

  li {
    margin: .5em 0;
  }

  .menu-item-has-children {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &.open {
      > .toggle-submenu {
        transform: rotate(90deg);
      }
    }
  }
}

[class^='primary-menu-mobile-sub'],
[class*=' primary-menu-mobile-sub'] {
  display: none;
  padding-left: rem(20);
  padding-top: rem(10);
  padding-bottom: rem(20);
  width: 100%;

  a {
    font-size: rem(16);
    line-height: lh(28, 16);
  }


  li.open > & {
    display: block;
  }
}
