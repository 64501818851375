/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@media screen and (max-width: 1440px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1439px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1250px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1249px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1100px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1099px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 990px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 989px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 768px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 767px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 600px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 599px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 480px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 479px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (min-width: 479px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 480px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 599px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 600px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 767px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 768px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 989px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 990px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1099px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1100px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1249px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1250px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1439px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1440px) {
  .foo {
    bar: 'above'; } }

@font-face {
  font-family: "DM Sans";
  font-weight: 300;
  font-style: normal;
  src: url("./static/fonts/dm-sans/dmsans-light-webfont.woff2") format("woff2"), url("./static/fonts/dm-sans/dmsans-light-webfont.woff") format("woff"); }

@font-face {
  font-family: "DM Sans";
  font-weight: 300;
  font-style: italic;
  src: url("./static/fonts/dm-sans/dmsans-lightitalic-webfont.woff2") format("woff2"), url("./static/fonts/dm-sans/dmsans-lightitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "DM Sans";
  font-weight: 400;
  font-style: normal;
  src: url("./static/fonts/dm-sans/dmsans-regular-webfont.woff2") format("woff2"), url("./static/fonts/dm-sans/dmsans-regular-webfont.woff") format("woff"); }

@font-face {
  font-family: "DM Sans";
  font-weight: 400;
  font-style: italic;
  src: url("./static/fonts/dm-sans/dmsans-italic-webfont.woff2") format("woff2"), url("./static/fonts/dm-sans/dmsans-italic-webfont.woff") format("woff"); }

@font-face {
  font-family: "DM Sans";
  font-weight: 500;
  font-style: normal;
  src: url("./static/fonts/dm-sans/dmsans-medium-webfont.woff2") format("woff2"), url("./static/fonts/dm-sans/dmsans-medium-webfont.woff") format("woff"); }

@font-face {
  font-family: "DM Sans";
  font-weight: 500;
  font-style: italic;
  src: url("./static/fonts/dm-sans/dmsans-mediumitalic-webfont.woff2") format("woff2"), url("./static/fonts/dm-sans/dmsans-mediumitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "DM Sans";
  font-weight: 700;
  font-style: normal;
  src: url("./static/fonts/dm-sans/dmsans-bold-webfont.woff2") format("woff2"), url("./static/fonts/dm-sans/dmsans-bold-webfont.woff") format("woff"); }

@font-face {
  font-family: "DM Sans";
  font-weight: 700;
  font-style: italic;
  src: url("./static/fonts/dm-sans/dmsans-bolditalic-webfont.woff2") format("woff2"), url("./static/fonts/dm-sans/dmsans-bolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "DM Sans";
  font-weight: 800;
  font-style: normal;
  src: url("./static/fonts/dm-sans/dmsans-extrabold-webfont.woff2") format("woff2"), url("./static/fonts/dm-sans/dmsans-extrabold-webfont.woff") format("woff"); }

@font-face {
  font-family: "DM Sans";
  font-weight: 800;
  font-style: italic;
  src: url("./static/fonts/dm-sans/dmsans-extrabolditalic-webfont.woff2") format("woff2"), url("./static/fonts/dm-sans/dmsans-extrabolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 200;
  font-style: normal;
  src: url("./static/fonts/nunito/nunito-extralight-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-extralight-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 200;
  font-style: italic;
  src: url("./static/fonts/nunito/nunito-extralightitalic-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-extralightitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 300;
  font-style: normal;
  src: url("./static/fonts/nunito/nunito-light-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-light-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 300;
  font-style: italic;
  src: url("./static/fonts/nunito/nunito-lightitalic-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-lightitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 400;
  font-style: normal;
  src: url("./static/fonts/nunito/nunito-regular-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-regular-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 400;
  font-style: italic;
  src: url("./static/fonts/nunito/nunito-italic-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-italic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 500;
  font-style: normal;
  src: url("./static/fonts/nunito/nunito-medium-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-medium-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 500;
  font-style: italic;
  src: url("./static/fonts/nunito/nunito-mediumitalic-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-mediumitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 700;
  font-style: normal;
  src: url("./static/fonts/nunito/nunito-bold-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-bold-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 700;
  font-style: italic;
  src: url("./static/fonts/nunito/nunito-bolditalic-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-bolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 800;
  font-style: normal;
  src: url("./static/fonts/nunito/nunito-extrabold-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-extrabold-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 800;
  font-style: italic;
  src: url("./static/fonts/nunito/nunito-extrabolditalic-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-extrabolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 900;
  font-style: normal;
  src: url("./static/fonts/nunito/nunito-black-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-black-webfont.woff") format("woff"); }

@font-face {
  font-family: "Nunito";
  font-weight: 900;
  font-style: italic;
  src: url("./static/fonts/nunito/nunito-blackitalic-webfont.woff2") format("woff2"), url("./static/fonts/nunito/nunito-blackitalic-webfont.woff") format("woff"); }

html {
  box-sizing: border-box;
  line-height: 1.15; }

*,
*::before,
*::after {
  box-sizing: inherit; }

:root {
  -moz-tab-size: 4;
  tab-size: 4; }

body {
  margin: 0;
  font-family: "soleil", sans-serif;
  color: #1f1f1f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

hr {
  height: 0; }

abbr[title] {
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp,
pre {
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  padding: 0; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

summary {
  display: list-item; }

nav ul {
  padding: 0;
  margin: 0;
  list-style: none; }

.screen-reader-text {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0); }

div.btn a, a.btn, button.btn, input.btn[type="submit"], .subscribe-field .wpcf7-submit, #cookie-notice.cn-position-bottom .cn-button {
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0.125rem;
  font-size: 0.8125rem;
  padding: 1.0625rem 1.25rem;
  min-width: 13.5rem;
  background: linear-gradient(90deg, #012784, #014cbc);
  color: #fff;
  border-radius: 0.9375rem;
  position: relative;
  z-index: 2;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer; }
  div.btn a::before, a.btn::before, button.btn::before, input.btn[type="submit"]::before, .subscribe-field .wpcf7-submit::before, #cookie-notice.cn-position-bottom .cn-button::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, #4c90f9, #0458f4);
    z-index: -1;
    transition: opacity 200ms linear;
    opacity: 0;
    border-radius: 0.9375rem; }
  div.btn a:hover, a.btn:hover, button.btn:hover, input.btn:hover[type="submit"], .subscribe-field .wpcf7-submit:hover, #cookie-notice.cn-position-bottom .cn-button:hover {
    color: #fff; }
    div.btn a:hover::before, a.btn:hover::before, button.btn:hover::before, input.btn[type="submit"]:hover::before, .subscribe-field .wpcf7-submit:hover::before, #cookie-notice.cn-position-bottom .cn-button:hover::before {
      opacity: 1; }

div.btn-outlined a, a.btn-outlined, button.btn-outlined, input.btn-outlined[type="submit"] {
  background: transparent;
  color: #121f8a;
  border: 0.0625rem solid #121f8a; }
  div.btn-outlined a::before, a.btn-outlined::before, button.btn-outlined::before, input.btn-outlined[type="submit"]::before {
    top: -0.0625rem;
    right: -0.0625rem;
    bottom: -0.0625rem;
    left: -0.0625rem; }

.checkbox {
  display: inline-block; }
  .checkbox__input {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0); }
  .checkbox__label::before {
    content: ''; }

.input-field {
  width: 100%;
  margin-bottom: 1.5625rem; }
  .input-field label {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0); }

@media screen and (min-width: 768px) {
  .fields-wrapper {
    display: flex;
    justify-content: space-between; }
  .input-field--half {
    width: calc(50% - 0.625rem); } }

.radio {
  display: inline-block; }
  .radio__input {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0); }
  .radio__label::before {
    content: ''; }

.subscribe-field {
  position: relative;
  width: 100%; }
  .subscribe-field .subscribe-email {
    position: relative;
    margin: 0; }
    .subscribe-field .subscribe-email input {
      text-align: center;
      border: none; }
  .subscribe-field .wpcf7-submit {
    width: 100%;
    min-width: 0;
    padding-left: 0;
    padding-right: 0;
    flex-shrink: 0;
    margin-top: 1.25rem;
    height: 2.8125rem; }
  .subscribe-field input {
    border: none; }

.wpcf7 form .subscribe-field .wpcf7-form-control-wrap {
  margin: 0; }

.wpcf7 form .subscribe-field .ajax-loader {
  display: none; }

.wpcf7 form.submitting .subscribe-field .subscribe-email,
.wpcf7 form.submitting .subscribe-field .wpcf7-submit {
  opacity: .5; }

.wpcf7 form.submitting .subscribe-field .ajax-loader {
  display: block;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 50%;
  margin-left: -0.75rem;
  margin-top: -0.75rem; }

@media screen and (min-width: 600px) {
  .subscribe-field {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; }
    .subscribe-field .subscribe-email input {
      text-align: left;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .subscribe-field .wpcf7-submit {
      width: 8.75rem;
      padding-left: 0;
      padding-right: 0;
      flex-shrink: 0;
      margin-top: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; } }

@media screen and (min-width: 768px) and (max-width: 1100px) {
  .subscribe-field {
    display: block; }
    .subscribe-field .subscribe-email input {
      text-align: center;
      border-radius: 0.9375rem; }
    .subscribe-field .wpcf7-submit {
      width: 100%;
      margin-top: 1.25rem;
      border-radius: 0.9375rem; } }

.text-input,
.wpcf7-text,
textarea {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  border-radius: 0.9375rem;
  font-size: 0.875rem;
  border: 1px solid #121f8a;
  width: 100%;
  height: 2.8125rem;
  outline: none; }
  .text-input[aria-invalid="true"],
  .wpcf7-text[aria-invalid="true"],
  textarea[aria-invalid="true"] {
    border-color: #e25050; }

textarea {
  padding: 1.125rem;
  height: 13.125rem; }

.select {
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  background: none;
  color: #121f8a;
  padding-right: 1.875rem; }
  .select_wrapper {
    position: relative;
    font-size: 1rem;
    border-bottom: 0.0625rem solid #121f8a;
    width: fit-content;
    z-index: 1;
    padding-top: 0;
    padding-bottom: .4em; }
    .select_wrapper::before {
      content: "";
      display: block;
      position: absolute;
      right: 0.625rem;
      top: 25%;
      height: 0.625rem;
      width: 0.125rem;
      background: #121f8a;
      transform: rotate(45deg);
      z-index: -1; }
    .select_wrapper::after {
      content: "";
      display: block;
      position: absolute;
      right: 1rem;
      top: 25%;
      height: 0.625rem;
      width: 0.125rem;
      background: #121f8a;
      transform: rotate(-45deg);
      z-index: -1; }

.wpcf7 {
  width: 100%; }
  .wpcf7 p {
    margin: 0; }
  .wpcf7 .wpcf7-not-valid-tip {
    font-size: 0.875rem;
    margin-top: 0.625rem;
    padding-left: 0.3125rem; }
  .wpcf7 .wpcf7-form-control-wrap {
    display: block;
    width: 100%;
    flex: 1;
    margin-right: 1.25rem;
    position: relative; }
    .wpcf7 .wpcf7-form-control-wrap input {
      width: 100%;
      display: block; }
  .wpcf7 form.invalid .wpcf7-response-output {
    display: none; }

a {
  color: #121f8a;
  transition: 200ms; }
  a svg {
    fill: currentColor; }
    a svg path {
      fill: currentColor; }
  a:hover {
    color: #bae5ff; }

.hdr-logo {
  margin: 0;
  height: 100%; }
  .hdr-logo img {
    display: block;
    height: 100%; }
  .hdr-logo span {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0); }

.hdr-logo--lrg img {
  width: 100%;
  max-width: 16.875rem;
  height: auto; }

.nav-toggle {
  width: 2.5rem;
  height: 2.5rem;
  background-color: transparent;
  appearance: none;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  justify-content: center;
  color: #121f8a;
  position: relative; }

.search-close {
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  background-color: transparent;
  display: flex;
  appearance: none;
  color: #1f1f1f;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  outline: none; }
  .search-close svg {
    width: 1.375rem;
    height: 1.375rem;
    display: block; }
    .search-close svg path {
      fill: currentColor; }

.search-toggle {
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  background-color: transparent;
  display: flex;
  appearance: none;
  color: #121f8a;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  outline: none; }
  .search-toggle svg {
    display: block; }
    .search-toggle svg path {
      fill: currentColor; }

body {
  font-family: "Nunito", sans-serif; }

p {
  font-size: 0.9375rem;
  line-height: 2; }
  p.body-small {
    font-size: 0.75rem; }
  p.body-big {
    font-size: 1.25rem; }

a {
  font-family: "Nunito", sans-serif; }

.btn a {
  font-family: "DM Sans", sans-serif;
  letter-spacing: 0.0625rem; }

h1,
h2,
h3,
h4 {
  color: #121f8a;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  margin: 0; }
  h1.center,
  h2.center,
  h3.center,
  h4.center {
    text-align: center; }
  h1.spacing,
  h2.spacing,
  h3.spacing,
  h4.spacing {
    margin-top: 1.375rem;
    margin-bottom: 3.125rem; }
  h1.underline,
  h2.underline,
  h3.underline,
  h4.underline {
    position: relative;
    display: inline-block;
    padding-bottom: 1.5625rem; }
    h1.underline:after,
    h2.underline:after,
    h3.underline:after,
    h4.underline:after {
      position: absolute;
      content: "";
      height: 0.25rem;
      width: 4.3125rem;
      border-radius: 0.25rem;
      background: linear-gradient(90deg, #121f8a, #0055f4);
      left: 0;
      bottom: 0; }
    h1.underline-center,
    h2.underline-center,
    h3.underline-center,
    h4.underline-center {
      text-align: center;
      display: flex;
      justify-content: center; }
      h1.underline-center:after,
      h2.underline-center:after,
      h3.underline-center:after,
      h4.underline-center:after {
        left: calc(50% - (4.3125rem / 2)); }

h1 {
  font-size: 3.125rem;
  line-height: 1.2; }

h2 {
  font-size: 2.25rem; }

h3 {
  font-size: 1.5rem;
  line-height: 1.41667; }

h4 {
  font-size: 1.125rem;
  font-weight: 500; }

@media screen and (max-width: 1250px) {
  h1 {
    font-size: 2.6875rem; }
  h2 {
    font-size: 1.875rem; }
  h3 {
    font-size: 1.125rem; }
  h4 {
    font-size: 1rem; } }

.toggle-submenu {
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: #121f8a;
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: transparent; }
  .toggle-submenu svg path {
    fill: currentColor; }

.icn-menu {
  width: 1.875rem;
  height: 1.875rem;
  display: block;
  position: relative; }
  .icn-menu__inner {
    top: 0.875rem;
    transition: background-color 0s ease calc(0.2s * 2); }
    .icn-menu__inner, .icn-menu__inner::before, .icn-menu__inner::after {
      display: block;
      width: 100%;
      height: 3px;
      position: absolute;
      background-color: #121f8a; }
    .icn-menu__inner::before, .icn-menu__inner::after {
      content: '';
      transition: top 0.2s ease calc(0.2s * 2), width 0.2s ease, transform 0.2s ease 0.2s; }
    .icn-menu__inner::before {
      top: -0.625rem; }
    .icn-menu__inner::after {
      top: 0.625rem;
      width: 50%; }

.nav-toggle:hover .icn-menu__inner::after {
  width: 100%; }

.nav-toggle[aria-expanded="true"] .icn-menu__inner {
  background-color: transparent;
  transition: background-color 0s ease 0.2s; }
  .nav-toggle[aria-expanded="true"] .icn-menu__inner::before, .nav-toggle[aria-expanded="true"] .icn-menu__inner::after {
    width: 100%;
    top: 0;
    transition: top 0.2s ease, width 0.2s ease, transform 0.2s ease 0.2s; }
  .nav-toggle[aria-expanded="true"] .icn-menu__inner::before {
    transform: rotate(-45deg); }
  .nav-toggle[aria-expanded="true"] .icn-menu__inner::after {
    transform: rotate(45deg); }

.nav-social {
  margin-top: -0.625rem; }
  .nav-social ul li {
    display: inline-block;
    vertical-align: bottom; }
  .nav-social ul li + li {
    margin-top: 0;
    margin-left: 0.625rem; }
  .nav-social svg path {
    fill: currentColor; }

.wave-list {
  list-style: none;
  font-size: 0.9375rem;
  line-height: 2;
  padding: 0; }
  .wave-list li {
    padding-left: 2.0625rem;
    position: relative; }
    .wave-list li:before {
      content: '';
      display: block;
      position: absolute;
      top: .7em;
      left: 0;
      width: 1.75rem;
      height: 1.3125rem;
      background-image: url("./static/img/wave.svg");
      background-repeat: no-repeat; }

.big_image_wrapper {
  height: 21.875rem;
  border-radius: 0 0 3.125rem 3.125rem;
  overflow: hidden;
  margin-bottom: 3.125rem;
  position: relative; }
  .big_image_wrapper picture {
    width: 100%;
    height: 100%;
    display: block; }
  .big_image_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .big_image_wrapper .constrict, .big_image_wrapper .constrict-md, .big_image_wrapper .constrict-sm, .big_image_wrapper .constrict-xs, .big_image_wrapper .constrict-lg {
    position: absolute; }

.breadcrumbs {
  margin-top: 2.3125rem;
  font-size: 0.6875rem; }
  .breadcrumbs a {
    text-decoration: none; }
    .breadcrumbs a:hover {
      text-decoration: underline; }

.collection-header {
  padding: 3.75rem 1.875rem 1.875rem;
  text-align: center;
  color: #fff;
  background: #121f8a linear-gradient(90deg, #121f8a, #0055f4);
  border-radius: 0 0 3.125rem 3.125rem;
  position: relative;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .collection-header {
      padding: 3.75rem 3.75rem 5rem; } }
  .collection-header .breadcrumbs {
    position: absolute;
    top: 0;
    z-index: 10; }
    .collection-header .breadcrumbs a {
      color: white; }
  .collection-header__visual {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    opacity: 0.13; }
    .collection-header__visual img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .collection-header__inner {
    max-width: 69.375rem;
    margin: auto;
    position: relative;
    z-index: 1; }
  .collection-header__title.h2 {
    color: inherit;
    margin-top: 0;
    margin-bottom: 1em; }
    .collection-header__title.h2:after {
      background: currentColor; }
  .collection-header__intro {
    font-size: 0.875rem;
    line-height: 1.6; }
    @media screen and (min-width: 768px) {
      .collection-header__intro {
        font-size: 0.9375rem;
        line-height: 1.8; } }

.contact {
  margin: 3.125rem 0; }
  .contact__title, .contact__intro, .contact__list {
    text-align: center; }
  .contact__intro, .contact__list, .contact__form {
    margin-top: 1.875rem; }

.contact-details-list {
  list-style: none;
  padding: 0; }
  .contact-details-list li {
    margin: 0.9375rem 0; }

.wpcf7 form.sent .wpcf7-response-output {
  border-radius: 1.875rem;
  padding: 1.375rem 2.1875rem;
  border-color: #bae5ff !important; }

@media screen and (min-width: 768px) {
  .contact {
    margin-bottom: 5.625rem; }
    .contact__intro {
      margin-top: 1.25rem; }
    .contact__list {
      margin-top: 2.5rem; }
    .contact__form {
      margin-top: 3.75rem; } }

.content_block_one_wrapper {
  max-width: 69.375rem;
  margin: auto; }

.content_block_two_wrapper {
  max-width: 69.375rem;
  margin: auto; }
  .content_block_two_wrapper .title {
    margin-bottom: 1.25rem; }
    .content_block_two_wrapper .title.center {
      text-align: center; }
  .content_block_two_wrapper .content-blocks {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 1.875rem; }

@media screen and (max-width: 768px) {
  .content_block_two_wrapper .content-blocks {
    grid-template-columns: 1fr; } }

.content_image_wrapper {
  max-width: 69.375rem;
  display: flex;
  justify-content: center;
  margin: auto;
  overflow: hidden; }
  .content_image_wrapper img {
    border-radius: 3.125rem 3.125rem 0 0; }

.cta_block.boxed .cta_block_wrapper {
  background: linear-gradient(90deg, #62c4ff, #bae5ff);
  border-radius: 3.125rem;
  max-width: 69.4375rem;
  position: relative;
  padding: 2.1875rem 5rem 2.1875rem; }
  .cta_block.boxed .cta_block_wrapper .wysiwyg > * {
    color: #121f8a; }
  .cta_block.boxed .cta_block_wrapper::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(./static/img/pattern.png);
    border-radius: 3.125rem;
    mix-blend-mode: screen;
    opacity: 0.5; }

.cta_block:not(.boxed) {
  position: relative;
  margin-bottom: 1.875rem; }
  .cta_block:not(.boxed)::before {
    display: block;
    content: "";
    position: absolute;
    left: -31.25rem;
    top: 0;
    height: 100%;
    width: 75%;
    background: url(./static/img/pattern.png);
    background-repeat: no-repeat;
    opacity: 0.5; }

.cta_block_wrapper {
  text-align: center;
  padding: 2.1875rem 5rem 0;
  max-width: 52.875rem;
  margin: auto; }
  .cta_block_wrapper .wysiwyg {
    margin-bottom: 1.875rem; }
  .cta_block_wrapper .buttons {
    display: flex;
    justify-content: center; }
    .cta_block_wrapper .buttons .btn {
      margin: 0 1.875rem; }

@media screen and (max-width: 1250px) {
  .cta_block:not(.boxed)::before {
    width: 60%; } }

@media screen and (max-width: 768px) {
  .cta_block.boxed .cta_block_wrapper {
    padding: 1.875rem 1.25rem 1.875rem; }
  .cta_block_wrapper {
    padding: 1.875rem 0 0; }
    .cta_block_wrapper .buttons {
      flex-direction: column; }
      .cta_block_wrapper .buttons .btn {
        margin: 0; }
        .cta_block_wrapper .buttons .btn:first-child {
          margin-bottom: 0.625rem; } }

.error-page {
  margin: 3.125rem 0; }
  .error-page__visual {
    margin: 0; }
    .error-page__visual img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto; }
  .error-page__content {
    text-align: center;
    max-width: 25rem;
    margin: 1.875rem auto 0; }
    .error-page__content .btn {
      width: 100%; }
  .error-page h2, .error-page p {
    margin: 1.25rem 0; }

@media screen and (min-width: 480px) {
  .error-page__content .btn {
    width: auto; } }

@media screen and (min-width: 990px) {
  .error-page__inner {
    display: flex;
    align-items: center;
    margin-top: 7.5rem; }
  .error-page__content {
    margin-top: 0;
    flex: 0 0 28.75rem;
    max-width: 100%;
    padding: 0 2.5rem; }
  .error-page h2, .error-page p {
    margin: 2.5rem 0; } }

.footer {
  overflow: hidden;
  background: #121f8a linear-gradient(90deg, #121f8a, #0055f4);
  color: white;
  position: relative;
  padding: 2.5rem 0; }
  .footer a, .footer h4, .footer h3 {
    color: inherit; }
  .footer::before, .footer::after {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    width: 49.125rem;
    height: 31.1875rem;
    background-image: url("./static/img/pattern.svg");
    z-index: 0; }
  .footer::before {
    right: 61.5%; }
  .footer::after {
    left: 59%;
    top: -0.625rem; }
  .footer__column {
    margin-bottom: 2.5rem; }
  .footer__legal, .footer__inner {
    position: relative;
    z-index: 1; }
  .footer__legal {
    text-align: center; }
  .footer .contact-list li + li,
  .footer .nav-footer li + li {
    margin-top: 1rem; }
  .footer .contact-list li.contact-list__item--email + li.contact-list__item--phone {
    margin-top: 0; }
  .footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.75rem;
    line-height: 1.5; }
    .footer ul a {
      text-decoration: none; }
      .footer ul a:hover {
        text-decoration: underline; }
  .footer__title {
    margin: 0 0 1rem; }
  .footer__legal {
    font-size: 0.75rem; }
  .footer .nav-legal {
    display: inline-block;
    margin: 0 0.9375rem; }
    .footer .nav-legal li {
      display: inline-block;
      margin: 0 0.9375rem; }
  .footer .copyright, .footer .nav-legal {
    margin: 0.625rem 0 0; }

@media screen and (min-width: 480px) {
  .footer__inner {
    display: flex;
    flex-wrap: wrap; }
  .footer__column {
    flex: 1 0 50%;
    padding-right: 1.875rem; }
    .footer__column--navigation {
      flex-basis: 100%; }
  .footer .nav-footer ul {
    columns: 2;
    column-gap: 1.875rem; } }

@media screen and (min-width: 768px) {
  .footer__column--navigation {
    flex-basis: 45%; }
  .footer__column--contact {
    flex-basis: 30%; }
  .footer__column--social {
    flex-basis: 25%; } }

.site-header {
  position: relative;
  z-index: 999;
  height: 3.75rem; }
  .site-header__logo {
    height: 2.8125rem; }
  .site-header__inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .site-header__secondary {
    display: flex;
    align-items: center; }
    .site-header__secondary * + * {
      margin-left: 0.625rem; }
    .site-header__secondary .btn {
      display: none; }

@media screen and (min-width: 990px) {
  .site-header__secondary .btn {
    min-width: 0;
    display: inline-block; }
  .site-header__secondary .nav-toggle {
    display: none; } }

.hero {
  position: relative;
  padding-top: 2.625rem;
  padding-bottom: 4.9375rem;
  margin-bottom: 1.875rem; }
  .hero > .background {
    position: absolute;
    overflow: hidden;
    background: linear-gradient(90deg, #121f8a, #0055f4);
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 0 0 3.125rem 3.125rem;
    top: 0; }
    .hero > .background img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: -1;
      mix-blend-mode: darken; }
  .hero .constrict, .hero .constrict-md, .hero .constrict-sm, .hero .constrict-xs, .hero .constrict-lg {
    height: 100%;
    padding-bottom: 0;
    padding-top: 0; }
  .hero_wrapper {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; }
    .hero_wrapper .title {
      color: white; }
      .hero_wrapper .title::after {
        background: white; }

.home-hero {
  position: relative;
  padding-top: 3.125rem; }
  .home-hero .constrict, .home-hero .constrict-md, .home-hero .constrict-sm, .home-hero .constrict-xs, .home-hero .constrict-lg {
    padding-bottom: 0;
    max-width: 87.5rem; }
  .home-hero__titles {
    color: #fff;
    margin-bottom: 1.25rem; }
  .home-hero .subtitle *, .home-hero .title {
    color: inherit; }
  .home-hero__background {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 6.25rem;
    left: 0;
    background: linear-gradient(90deg, #121f8a, #0055f4); }
    .home-hero__background::before {
      position: absolute;
      top: 0;
      right: 61.5%;
      content: '';
      display: block;
      width: 49.125rem;
      height: 31.1875rem;
      background-image: url("./static/img/pattern.png");
      z-index: 1;
      opacity: .4; }
    .home-hero__background img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      mix-blend-mode: darken; }
  .home-hero__inner {
    position: relative;
    z-index: 1; }
  .home-hero .category {
    text-align: center;
    color: #121f8a;
    border-radius: 3.125rem;
    overflow: hidden;
    background: linear-gradient(255deg, #bae5ff, #62c4ff);
    display: flex;
    height: 9.375rem;
    margin: 0.9375rem 0; }
    .home-hero .category__text {
      display: none; }
    .home-hero .category__link {
      font-family: "Stratos", sans-serif;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8125rem; }
    .home-hero .category__image {
      width: 40%;
      min-width: 9.375rem; }
      .home-hero .category__image img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .home-hero .category__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      padding: auto 0.625rem; }
      .home-hero .category__content h3 {
        margin: 0;
        margin-bottom: 0.3125rem; }

@media screen and (min-width: 768px) {
  .home-hero__background {
    bottom: 6.875rem; }
  .home-hero__categories {
    display: flex;
    align-items: flex-end;
    margin: 0 -0.9375rem; }
  .home-hero .category {
    height: 22.8125rem;
    margin: 0 0.9375rem;
    flex-direction: column; }
    .home-hero .category__image {
      width: 100%;
      min-width: 100%;
      height: calc(100% - 6.875rem); }
    .home-hero .category__content {
      min-height: 6.875rem; } }

@media screen and (min-width: 990px) {
  .home-hero__titles {
    position: absolute;
    right: 0;
    top: 1.25rem;
    left: 38%;
    height: 12.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; }
  .home-hero .category {
    flex: 0 1 17.3125rem; }
  .home-hero .category--main {
    flex: 1 1 26.625rem;
    height: 38.4375rem; }
    .home-hero .category--main .category__text {
      display: block;
      margin: 0.625rem 0 1.25rem; }
    .home-hero .category--main .category__image {
      height: 100%;
      flex: 1 1 auto; }
    .home-hero .category--main .category__content {
      min-height: unset;
      padding: 2.5rem 1.875rem 1.875rem; } }

.home_products {
  position: relative; }
  .home_products::before {
    position: absolute;
    content: "";
    display: block;
    top: -5rem;
    left: 0;
    bottom: 0;
    right: 0;
    background: url("./static/img/pattern.png");
    background-repeat: no-repeat;
    background-position: calc(100% + 37.5rem) -18.125rem;
    z-index: -1;
    opacity: 0.5; }
  .home_products_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 1.9375rem;
    padding-top: 18.75rem; }
    .home_products_wrapper .product {
      box-shadow: 0 10px 20px rgba(18, 31, 138, 0.15);
      border-radius: 3.125rem;
      text-align: center;
      padding: 9.1875rem 2.9375rem 2.9375rem;
      position: relative;
      background: #fff;
      color: #121f8a; }
      .home_products_wrapper .product .images {
        bottom: 70%;
        left: 0;
        position: absolute;
        height: 18.75rem;
        width: 100%; }
        .home_products_wrapper .product .images:hover picture:first-child {
          opacity: 0; }
        .home_products_wrapper .product .images:hover picture:last-child {
          opacity: 1; }
        .home_products_wrapper .product .images picture {
          position: absolute;
          object-fit: contain;
          width: 100%;
          height: 100%;
          bottom: 0;
          transition: 200ms;
          left: 0; }
          .home_products_wrapper .product .images picture img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
          .home_products_wrapper .product .images picture:last-child {
            opacity: 0; }

@media screen and (max-width: 990px) {
  .home_products_wrapper {
    padding-top: 21.875rem; }
    .home_products_wrapper .product .images {
      width: 70%;
      margin: 0 15%; } }

@media screen and (max-width: 768px) {
  .home_products_wrapper {
    padding-top: 11.25rem;
    grid-template-columns: 1fr;
    grid-gap: 1.25rem; }
    .home_products_wrapper .product {
      padding: 1.875rem 1.25rem; }
      .home_products_wrapper .product .images {
        position: relative;
        height: 12.5rem;
        width: 100%;
        bottom: unset;
        margin: 0;
        margin-bottom: 1.25rem; }
        .home_products_wrapper .product .images img {
          object-fit: contain;
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: unset; } }

.image_gallery_wrapper {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: repeat(2, 50%);
  height: 50vh;
  margin-bottom: 3.125rem; }
  .image_gallery_wrapper picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
    .image_gallery_wrapper picture img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .image_gallery_wrapper picture:nth-child(1) {
      grid-column: span 2;
      grid-row: span 2; }
    .image_gallery_wrapper picture:nth-child(3) {
      grid-row: span 2; }

@media screen and (max-width: 768px) {
  .image_gallery_wrapper {
    grid-template-columns: repeat(2, 50%); }
    .image_gallery_wrapper picture:nth-child(1) {
      grid-column: 1;
      grid-row: 1; }
    .image_gallery_wrapper picture:nth-child(3) {
      grid-row: 2; } }

.mobile-menu {
  position: fixed;
  top: 3.75rem;
  left: 0;
  right: 0;
  z-index: 9999; }
  .mobile-menu__inner {
    height: calc(100vh - 3.75rem);
    position: absolute;
    top: 0;
    transform: translateX(100%);
    max-width: 25rem;
    width: 100%;
    overflow-y: auto;
    right: 0;
    background-color: #fff;
    transition: transform .3s ease;
    padding: 1.875rem; }
  .mobile-menu__primary .btn {
    margin-top: 1.875rem;
    width: 100%; }
  .mobile-menu.show .mobile-menu__inner {
    transform: translateX(0); }

.admin-bar .mobile-menu {
  top: 6.625rem; }
  @media screen and (min-width: 783px) {
    .admin-bar .mobile-menu {
      top: 5.75rem; } }
  .admin-bar .mobile-menu__inner {
    height: calc(100vh - 6.625rem); }
    @media screen and (min-width: 783px) {
      .admin-bar .mobile-menu__inner {
        height: calc(100vh - 5.75rem); } }

.menu-open {
  overflow-y: hidden; }

@media screen and (min-width: 768px) {
  .menu-open {
    height: 100vh;
    padding-right: 15px; } }

@media screen and (min-width: 1100px) {
  .mobile-menu {
    display: none; } }

.pagination {
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .pagination li {
    width: 1.5625rem;
    height: 2.5rem;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.3125rem; }
    .pagination li.first, .pagination li.last, .pagination li.prev, .pagination li.next {
      margin: 0;
      width: 1.5625rem; }
    .pagination li > a, .pagination li > span {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.875rem;
      text-decoration: none;
      color: #1f1f1f; }
    .pagination li > a:hover {
      color: #121f8a; }
    .pagination li.current > a, .pagination li.current > span {
      color: #121f8a;
      font-weight: 700; }
    .pagination li svg path {
      fill: #121f8a; }
    .pagination li.disabled {
      opacity: .4; }

.partners_wrapper {
  margin-top: 4.75rem; }
  .partners_wrapper .title {
    margin-bottom: 1.875rem;
    text-align: center; }

.partners_collection {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 55rem;
  margin: auto; }
  .partners_collection .slick-arrow {
    margin: 1.25rem; }
  .partners_collection a {
    margin: 0 0.9375rem;
    text-decoration: none; }

.posts {
  padding-top: 2.6875rem; }
  .posts.background {
    background: linear-gradient(90deg, #121f8a, #0055f4);
    border-radius: 0 0 3.125rem 3.125rem; }
    .posts.background .title {
      color: #fff; }
      .posts.background .title::after {
        background: #fff; }
    .posts.background .posts-grid article,
    .posts.background .posts-grid h4,
    .posts.background .posts-grid a {
      color: #fff; }
  .posts_wrapper .title {
    margin-bottom: 3.125rem; }
  .posts_wrapper .posts-grid {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1.875rem;
    grid-auto-columns: 1fr; }
    .posts_wrapper .posts-grid.latest_two article:not(.tease-products) .category picture {
      height: 21.875rem; }
    .posts_wrapper .posts-grid article:not(.tease-products):not(.tease-table) {
      display: grid;
      grid-template-rows: 18.75rem 1fr;
      text-decoration: none;
      position: relative;
      margin: 0.9375rem; }
      .posts_wrapper .posts-grid article:not(.tease-products):not(.tease-table) .category {
        position: absolute;
        padding: 0.8125rem 2rem;
        top: -1.25rem;
        left: 10%;
        background: linear-gradient(90deg, #62c4ff, #bae5ff);
        border-radius: 0 0.625rem 0 0.625rem;
        margin: 0;
        line-height: 1.2;
        color: #121f8a;
        z-index: 1; }
      .posts_wrapper .posts-grid article:not(.tease-products):not(.tease-table) picture {
        overflow: hidden;
        border-radius: 3.125rem 3.125rem 0 0;
        height: 18.75rem; }
        .posts_wrapper .posts-grid article:not(.tease-products):not(.tease-table) picture img {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%; }
      .posts_wrapper .posts-grid article:not(.tease-products):not(.tease-table) .content {
        margin-top: 0.625rem;
        display: flex;
        flex-direction: column;
        justify-content: s; }
        .posts_wrapper .posts-grid article:not(.tease-products):not(.tease-table) .content h4 {
          font-weight: bold;
          margin-top: 0.625rem; }
        .posts_wrapper .posts-grid article:not(.tease-products):not(.tease-table) .content a {
          text-decoration: underline;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 0.8125rem; }

@media screen and (max-width: 768px) {
  .posts_wrapper .posts-grid {
    grid-template-columns: 1fr;
    grid-auto-flow: row; }
    .posts_wrapper .posts-grid.latest_three, .posts_wrapper .posts-grid.latest_two, .posts_wrapper .posts-grid.custom {
      grid-template-columns: 1fr;
      grid-auto-flow: row; } }

.primary-menu-mobile a {
  font-size: 1.125rem;
  line-height: 1.66667;
  text-decoration: none; }

.primary-menu-mobile li {
  margin: .5em 0; }

.primary-menu-mobile .menu-item-has-children {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  .primary-menu-mobile .menu-item-has-children.open > .toggle-submenu {
    transform: rotate(90deg); }

[class^='primary-menu-mobile-sub'],
[class*=' primary-menu-mobile-sub'] {
  display: none;
  padding-left: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 1.25rem;
  width: 100%; }
  [class^='primary-menu-mobile-sub'] a,
  [class*=' primary-menu-mobile-sub'] a {
    font-size: 1rem;
    line-height: 1.75; }
  li.open > [class^='primary-menu-mobile-sub'], li.open >
  [class*=' primary-menu-mobile-sub'] {
    display: block; }

.primary-menu {
  display: none; }

@media screen and (min-width: 990px) {
  .primary-menu {
    display: flex; }
    .primary-menu > li + li {
      margin-left: 1.875rem; }
    .primary-menu > li {
      position: relative;
      padding: 0.625rem 0; }
      .primary-menu > li.menu-item-has-children {
        position: relative;
        padding-right: 1rem; }
        .primary-menu > li.menu-item-has-children > a:after {
          width: 0.375rem;
          height: 0.375rem;
          content: "";
          display: block;
          border: 1px solid currentColor;
          border-width: 0 0 1px 1px;
          position: absolute;
          top: 1.0625rem;
          right: 0;
          transform: rotate(-45deg); }
      .primary-menu > li:hover .primary-menu-sub {
        display: block; }
      .primary-menu > li.submenu-columns:hover .primary-menu-sub {
        display: flex; }
    .primary-menu a {
      font-size: 0.8125rem;
      font-weight: 600;
      text-decoration: none; }
    .primary-menu .current_page_ancestor > a,
    .primary-menu .current_page_item > a,
    .primary-menu .current-menu-item > a {
      text-decoration: underline; }
  .primary-menu-sub {
    display: none;
    margin-top: 0;
    z-index: 99;
    position: absolute;
    background-color: #fff;
    padding: 1.875rem 1.875rem 2.5rem;
    margin-left: -1.875rem;
    border-radius: 0 0 1.25rem 1.25rem;
    box-shadow: 0 0.625rem 0.625rem rgba(0, 0, 0, 0.05); }
    .primary-menu-sub a {
      font-size: 0.9375rem;
      line-height: 1.4;
      display: block;
      color: #1f1f1f;
      padding: 0.3em 0; }
      .primary-menu-sub a:hover {
        text-decoration: underline; }
  .submenu-columns .primary-menu-sub {
    width: 21.875rem;
    justify-content: space-between; }
    .submenu-columns .primary-menu-sub > li {
      width: calc(50% - 0.625rem); }
      .submenu-columns .primary-menu-sub > li > a {
        color: #121f8a; }
      .submenu-columns .primary-menu-sub > li > ul {
        margin-top: 0.625rem; } }

.product_advantages {
  border-radius: 0 0 3.125rem 3.125rem;
  position: relative;
  padding-top: 2.8125rem;
  margin-bottom: 1.875rem; }
  .product_advantages .title {
    color: #fff; }
  .product_advantages > .background {
    position: absolute;
    overflow: hidden;
    background: linear-gradient(#121f8a, #0055f4);
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 0 0 3.125rem 3.125rem; }
    .product_advantages > .background img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0.1;
      mix-blend-mode: multiply; }
  .product_advantages.home {
    margin-top: -8.75rem;
    padding-top: 8.75rem; }
  .product_advantages_wrapper {
    color: #fff;
    text-align: center; }
    .product_advantages_wrapper .header {
      max-width: 37.5rem;
      margin: auto; }
    .product_advantages_wrapper h3,
    .product_advantages_wrapper a {
      color: #fff; }
    .product_advantages_wrapper .read-more {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8125rem; }
    .product_advantages_wrapper .advantages {
      display: grid;
      grid-gap: 1.875rem;
      grid-auto-flow: column; }
      .product_advantages_wrapper .advantages .slick-list {
        margin-top: 2.375rem;
        margin-bottom: 1.8125rem; }
      .product_advantages_wrapper .advantages .advantage {
        text-align: left;
        padding: 0 0.9375rem; }
        .product_advantages_wrapper .advantages .advantage .title {
          display: flex;
          align-items: center;
          height: 4.3125rem; }
          .product_advantages_wrapper .advantages .advantage .title img {
            width: 4.3125rem;
            height: auto;
            max-height: 4.3125rem;
            margin-right: 0.625rem; }

@media screen and (max-width: 990px) {
  .product_advantages_wrapper .advantages {
    display: flex;
    align-items: center; }
    .product_advantages_wrapper .advantages .slick-arrow svg path {
      fill: white; } }

.accordeon + .product-downloads {
  margin-top: 3.125rem; }

.product-downloads {
  background: #f2f2f2;
  padding: 2.5rem 0; }
  .product-downloads_wrapper .download {
    display: flex; }
  .product-downloads_wrapper .products {
    display: none;
    margin-top: 1.875rem; }
    .product-downloads_wrapper .products_wrapper {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      align-items: center;
      grid-gap: 0 4.375rem; }
    .product-downloads_wrapper .products .product-images {
      justify-self: end; }
    .product-downloads_wrapper .products .product-select {
      display: flex;
      align-items: center;
      margin-bottom: 2.1875rem; }
      .product-downloads_wrapper .products .product-select_label {
        margin-right: 1.6875rem; }
      .product-downloads_wrapper .products .product-select .select {
        max-width: 15.625rem; }
        .product-downloads_wrapper .products .product-select .select_wrapper {
          height: fit-content;
          max-width: 15.625rem; }
    .product-downloads_wrapper .products .product-files .product-download {
      display: grid;
      grid-gap: 1.875rem 0; }
    .product-downloads_wrapper .products .product-files a {
      text-transform: uppercase;
      text-decoration: none;
      display: flex;
      align-items: center; }
      .product-downloads_wrapper .products .product-files a:hover {
        color: #121f8a;
        text-decoration: underline; }
      .product-downloads_wrapper .products .product-files a svg {
        flex-shrink: 0;
        width: 0.75rem;
        height: 0.75rem;
        margin-right: 0.3125rem; }

@media screen and (max-width: 990px) {
  .product-downloads_wrapper .products_wrapper {
    grid-template-columns: 1fr;
    justify-items: center; }
    .product-downloads_wrapper .products_wrapper .product-images {
      display: none !important; }
    .product-downloads_wrapper .products_wrapper .product-download-details {
      margin-top: 1.25rem; } }

.products-intro {
  position: sticky;
  top: 0;
  background-color: #bae5ff;
  border-radius: 0 3.125rem 0 0;
  padding: 1.875rem;
  display: flex;
  align-items: center;
  margin-top: 1.25rem; }
  .products-intro::after {
    content: '';
    position: absolute;
    z-index: -1;
    display: block;
    width: 100vw;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: inherit;
    border-radius: inherit; }
  .products-intro__title {
    margin-bottom: .5em; }
    .products-intro__title.underline {
      padding-bottom: .7em; }

@media screen and (min-width: 768px) {
  .products-intro {
    padding: 3.125rem;
    min-height: 25rem; }
    .products-intro.has-negative-margin {
      margin-top: -3.125rem; } }

.search-bar {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  display: none; }
  .search-bar__inner {
    width: 100%;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .search-bar__inner .searchform {
      width: 100%; }
  .search-bar .search-close {
    position: absolute;
    right: 0.625rem;
    top: 50%;
    margin-top: -1.25rem; }
  .search-bar.show {
    display: block; }

.admin-bar .search-bar {
  top: 46px; }
  @media screen and (min-width: 783px) {
    .admin-bar .search-bar {
      top: 32px; } }

.searchform-field {
  border-radius: 0.9375rem;
  border: 1px solid #121f8a;
  max-width: 38.125rem;
  margin-left: auto;
  margin-right: 1.875rem;
  display: flex;
  overflow: hidden; }
  .searchform-field input {
    border: none;
    flex: 1;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    height: 2.5rem;
    outline: none; }
  .searchform-field button {
    width: 3.75rem;
    height: 2.5rem;
    color: #121f8a;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    appearance: none;
    border: none;
    background-color: transparent;
    outline: none; }
    .searchform-field button svg {
      display: block; }
      .searchform-field button svg path {
        fill: currentColor; }

@media screen and (min-width: 768px) {
  .search-bar .search-close {
    right: 1.875rem; }
  .search-bar .searchform-field {
    margin-right: auto; } }

.search-results {
  margin: 3.125rem 0; }

.single-post .single__title {
  margin-bottom: 1.25rem; }

.subscribe-block {
  margin-top: 3.125rem; }
  .subscribe-block__content {
    background-color: #bae5ff;
    border-radius: 0 3.125rem 0 0;
    padding: 1.875rem 1.25rem;
    padding-left: 0;
    position: relative;
    max-width: 68.75rem;
    margin-right: -1.25rem; }
    .subscribe-block__content::after {
      content: '';
      position: absolute;
      z-index: -1;
      display: block;
      width: 100vw;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: inherit;
      border-radius: inherit; }
    .subscribe-block__content p {
      margin: 0; }
  .subscribe-block__intro {
    margin: 0 0 1.25rem 0; }
  .subscribe-block__logo {
    display: none; }

@media screen and (min-width: 768px) {
  .subscribe-block__inner {
    display: flex; }
  .subscribe-block__content {
    flex: 1 1 auto;
    margin-right: unset;
    padding-right: 1.875rem; }
  .subscribe-block__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1.875rem;
    flex: 0 1 16.875rem; }
    .subscribe-block__logo .hdr-logo {
      height: auto; }
    .subscribe-block__logo img {
      max-width: 16.875rem; }
  .subscribe-block__form {
    display: flex;
    justify-content: center;
    align-items: center; } }

@media screen and (min-width: 990px) {
  .subscribe-block__content {
    min-height: 10.625rem;
    display: flex;
    align-items: center;
    padding-right: 0; }
  .subscribe-block__intro {
    margin: 0; }
  .subscribe-block__form {
    padding: 0 1.875rem;
    flex: 1; }
    .subscribe-block__form .wpcf7 {
      max-width: 30rem; } }

@media screen and (min-width: 1250px) {
  .subscribe-block__logo {
    flex-basis: 22.5rem; } }

.tease-table {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .tease-table .table {
    text-align: center; }
    .tease-table .table tr:nth-of-type(odd) {
      background: #f2f2f2; }
    .tease-table .table tr:nth-of-type(even) {
      background: #fff; }
    .tease-table .table th {
      background: #121f8a;
      padding: 1.25rem;
      color: #fff; }
    .tease-table .table td {
      padding: 0.625rem;
      min-width: 11.25rem; }

.tease-products {
  padding: 0.9375rem;
  height: 100%; }
  .tease-products__inner {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .tease-products__visual {
    margin: 0;
    flex-shrink: 0; }
    .tease-products__visual img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: auto; }
  .tease-products__title {
    flex: 1 0 auto;
    font-size: 0.9375rem;
    color: #1f1f1f;
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
    font-weight: 500;
    text-align: center; }
  .tease-products__cta {
    flex-shrink: 0; }
  .tease-products div.btn a {
    max-width: 100%;
    display: block;
    min-width: 0; }

.tease-search + .tease-search {
  margin-top: 1.875rem; }

.tease-search mark {
  background-color: rgba(186, 229, 255, 0.5); }

.tease-search__inner {
  display: grid;
  grid-template-columns: 1fr 6.25rem;
  gap: 1.25rem;
  align-items: center; }
  @media screen and (min-width: 768px) {
    .tease-search__inner {
      grid-template-columns: 1fr 12.5rem; } }

.tease-search__title {
  font-size: 1.125rem;
  line-height: 1.5;
  margin: 0;
  font-weight: 700; }
  .tease-search__title a,
  .tease-search__title a:hover {
    text-decoration: none;
    color: inherit; }

.tease-search__body {
  width: 100%; }

.tease-search__excerpt p {
  margin: 0; }

.tease-search .link {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8125rem; }

.tease-search__link {
  margin-top: 0.3125rem; }

.tease-search__visual {
  margin: 0;
  width: 6.25rem;
  height: 4.375rem;
  border-radius: 1.25rem 1.25rem 0 0;
  overflow: hidden; }
  .tease-search__visual img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.tease-search--products .tease-search__visual {
  border-radius: 0;
  width: 6.25rem;
  height: 6.25rem; }
  .tease-search--products .tease-search__visual img {
    object-fit: contain; }

@media screen and (min-width: 768px) {
  .tease-search {
    min-height: 7.1875rem; }
    .tease-search__visual {
      width: 12.5rem;
      height: 7.1875rem;
      border-radius: 2.5rem 2.5rem 0 0; }
  .tease-search--products .tease-search__visual {
    width: 12.5rem;
    height: 7.1875rem; } }

.text_with_image {
  overflow: hidden;
  margin-bottom: 1.875rem; }
  .text_with_image + .product_advantages,
  .text_with_image + .posts {
    margin-top: -1.875rem; }
  .text_with_image.pattern .text_with_image_wrapper {
    grid-template-columns: 30% 70%;
    position: relative;
    padding: 0 1.875rem; }
    .text_with_image.pattern .text_with_image_wrapper::after {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 100%;
      top: 0;
      background: url("./static/img/pattern.png");
      background-repeat: no-repeat; }
  .text_with_image.pattern.image-right .text_with_image_wrapper {
    grid-template-columns: 70% 30%;
    position: relative; }
    .text_with_image.pattern.image-right .text_with_image_wrapper::after {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      right: 100%;
      left: unset;
      top: 0;
      background: url("./static/img/pattern.png");
      background-repeat: no-repeat;
      background-position: right; }
  .text_with_image.image-right .text_with_image_wrapper .image img {
    right: unset;
    left: 0; }
  .text_with_image.image-right .text_with_image_wrapper .content {
    grid-column: 1;
    grid-row: 1;
    justify-self: start;
    padding-right: 6.25rem;
    padding-left: 0; }
  .text_with_image .constrict, .text_with_image .constrict-md, .text_with_image .constrict-sm, .text_with_image .constrict-xs, .text_with_image .constrict-lg {
    padding-bottom: 0; }
  .text_with_image_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    align-items: center;
    grid-gap: 1.875rem;
    max-width: 69.375rem;
    margin: auto;
    min-height: 25rem; }
    .text_with_image_wrapper .image {
      width: 100%;
      height: 100%;
      position: relative; }
      .text_with_image_wrapper .image img {
        border-radius: 3.125rem 3.125rem 0 0;
        width: auto;
        height: 100%;
        display: block;
        position: absolute;
        right: 0; }
    .text_with_image_wrapper .content {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      padding-left: 6.25rem; }
      .text_with_image_wrapper .content .btn {
        margin-top: 1.8125rem; }

@media screen and (max-width: 990px) {
  .text_with_image.pattern {
    background: none; }
    .text_with_image.pattern .text_with_image_wrapper::after {
      display: none; }
    .text_with_image.pattern.image-right .text_with_image_wrapper::after {
      display: none; }
    .text_with_image.pattern.image-right .image {
      margin-right: 0; }
    .text_with_image.pattern .image {
      margin-left: 0; }
  .text_with_image.image-right .text_with_image_wrapper .content {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    margin-top: 1.875rem;
    max-width: unset; }
  .text_with_image_wrapper {
    display: block;
    min-height: unset; }
    .text_with_image_wrapper .image {
      margin-left: 0; }
      .text_with_image_wrapper .image img {
        position: inherit;
        width: 100%; }
    .text_with_image_wrapper .content {
      margin-right: 0;
      margin-left: 0;
      padding-right: 0;
      padding-left: 0;
      margin-top: 1.875rem;
      max-width: unset; } }

#cookie-notice {
  position: fixed;
  min-width: 100%;
  height: auto;
  z-index: 100000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  left: 0;
  text-align: center;
  /* border-top: 2px solid #fbb03b; */
  font-weight: normal;
  font-family: -apple-system,BlinkMacSystemFont,Arial,Roboto,"Helvetica Neue",sans-serif; }

#cookie-notice,
#cookie-notice * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

#cookie-notice.cn-animated {
  -webkit-animation-duration: 0.5s !important;
  animation-duration: 0.5s !important;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

#cookie-notice.cn-animated.cn-effect-none {
  -webkit-animation-duration: 0.001s !important;
  animation-duration: 0.001s !important; }

#cookie-notice .cookie-notice-container {
  display: block; }

#cookie-notice.cookie-notice-hidden .cookie-notice-container {
  display: none; }

#cookie-notice .cookie-revoke-container {
  display: block; }

#cookie-notice.cookie-revoke-hidden .cookie-revoke-container {
  display: none; }

.cn-position-top {
  top: 0; }

.cn-position-bottom {
  bottom: 0; }

.cookie-notice-container {
  padding: 15px 30px;
  text-align: center;
  width: 100%;
  z-index: 2; }

.cookie-revoke-container {
  padding: 15px 30px;
  width: 100%;
  z-index: 1; }

.cn-close-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
  width: 15px;
  height: 15px;
  opacity: 0.5;
  padding: 10px;
  outline: none; }

.cn-close-icon:hover {
  opacity: 1; }

.cn-close-icon:before,
.cn-close-icon:after {
  position: absolute;
  content: ' ';
  height: 15px;
  width: 2px;
  top: 3px;
  background-color: #fff; }

.cn-close-icon:before {
  transform: rotate(45deg); }

.cn-close-icon:after {
  transform: rotate(-45deg); }

#cookie-notice .cn-revoke-cookie {
  margin: 0; }

#cookie-notice .cn-button {
  margin: 0 0 0 10px;
  border: none; }

.cn-button {
  font-family: -apple-system,BlinkMacSystemFont,Arial,Roboto,"Helvetica Neue",sans-serif;
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: none;
  display: inline-block;
  cursor: pointer;
  touch-action: manipulation;
  white-space: nowrap;
  outline: none;
  box-shadow: none;
  text-shadow: none;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.cn-button:hover {
  box-shadow: inset 0 0 0 99999px rgba(0, 0, 0, 0.05);
  text-decoration: none; }

.cn-button.wp-default,
.cn-button.bootstrap {
  text-decoration: none;
  padding: 8.5px 10px;
  line-height: 1; }

.cn-button.wp-default {
  color: #fff;
  background: #fbb03b; }

.cn-button.bootstrap {
  color: #fff;
  background: #20C19E; }

.cn-text-container {
  margin: 0 0 6px 0; }

.cn-text-container,
.cn-buttons-container {
  display: inline-block; }

#cookie-notice.cookie-notice-visible.cn-effect-none,
#cookie-notice.cookie-revoke-visible.cn-effect-none {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

#cookie-notice.cn-effect-none {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

#cookie-notice.cookie-notice-visible.cn-effect-fade,
#cookie-notice.cookie-revoke-visible.cn-effect-fade {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

#cookie-notice.cn-effect-fade {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

#cookie-notice.cookie-notice-visible.cn-effect-slide,
#cookie-notice.cookie-revoke-visible.cn-effect-slide {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

#cookie-notice.cn-effect-slide {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown; }

#cookie-notice.cookie-notice-visible.cn-position-top.cn-effect-slide,
#cookie-notice.cookie-revoke-visible.cn-position-top.cn-effect-slide {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown; }

#cookie-notice.cn-position-top.cn-effect-slide {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@media all and (max-width: 900px) {
  .cookie-notice-container #cn-notice-text {
    display: block; }
  .cookie-notice-container #cn-notice-buttons {
    display: block; }
  #cookie-notice .cn-button {
    margin: 0 5px 5px 5px; } }

@media all and (max-width: 480px) {
  .cookie-notice-container,
  .cookie-revoke-container {
    padding: 15px 25px; } }

#cookie-notice.cookie-notice-visible {
  background-color: #fff !important;
  box-shadow: 0 -1.25rem 1.25rem 0 rgba(18, 31, 138, 0.15); }

#cookie-notice.cn-position-bottom {
  display: none; }
  #cookie-notice.cn-position-bottom.cookie-notice-visible {
    display: block; }
  #cookie-notice.cn-position-bottom > .cookie-notice-container {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    width: 100%;
    max-width: 65.625rem;
    margin: auto;
    color: #1f1f1f !important; }
  #cookie-notice.cn-position-bottom .cn-text-container {
    width: 100%;
    max-width: 43.75rem;
    text-align: left;
    font-size: 0.9375rem;
    margin: 0; }
    #cookie-notice.cn-position-bottom .cn-text-container h4 {
      margin-bottom: 0.625rem; }
  #cookie-notice.cn-position-bottom .cn-buttons-container {
    text-align: left;
    margin-top: 1.25rem; }
    #cookie-notice.cn-position-bottom .cn-buttons-container .cn-button {
      margin-left: 0;
      margin-right: 0; }
  #cookie-notice.cn-position-bottom .cn-close-icon {
    top: 1.25rem; }
  #cookie-notice.cn-position-bottom #cn-close-notice::before, #cookie-notice.cn-position-bottom #cn-close-notice::after {
    background-color: #121f8a !important; }

@media screen and (min-width: 768px) {
  #cookie-notice.cn-position-bottom > .cookie-notice-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center; }
  #cookie-notice.cn-position-bottom .cn-close-icon {
    top: 50%; }
  #cookie-notice.cn-position-bottom .cn-buttons-container {
    margin-top: 0; } }

.blog .title,
.post-type-archive-cases .title {
  text-align: center;
  font-size: 3.125rem;
  line-height: 1.9;
  margin-bottom: 0.625rem; }

.blog .posts-grid,
.post-type-archive-cases .posts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5.9375rem 1.875rem; }
  .blog .posts-grid article,
  .post-type-archive-cases .posts-grid article {
    display: grid;
    grid-template-rows: 15.625rem 1fr;
    text-decoration: none;
    position: relative; }
    .blog .posts-grid article .category,
    .post-type-archive-cases .posts-grid article .category {
      position: absolute;
      padding: 0.8125rem 2rem;
      top: -1.25rem;
      left: 10%;
      background: linear-gradient(90deg, #62c4ff, #bae5ff);
      border-radius: 0 0.625rem 0 0.625rem;
      margin: 0;
      line-height: 1.2;
      color: #121f8a;
      z-index: 1; }
    .blog .posts-grid article picture,
    .post-type-archive-cases .posts-grid article picture {
      overflow: hidden;
      border-radius: 3.125rem 3.125rem 0 0;
      height: 15.625rem; }
      .blog .posts-grid article picture img,
      .post-type-archive-cases .posts-grid article picture img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%; }
    .blog .posts-grid article .content,
    .post-type-archive-cases .posts-grid article .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 0.625rem; }
      .blog .posts-grid article .content h4,
      .post-type-archive-cases .posts-grid article .content h4 {
        font-weight: bold;
        margin-top: 0.625rem; }
      .blog .posts-grid article .content a,
      .post-type-archive-cases .posts-grid article .content a {
        text-decoration: underline;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.8125rem; }

@media screen and (max-width: 768px) {
  .blog .posts-grid,
  .post-type-archive-cases .posts-grid {
    grid-template-columns: repeat(2, 1fr); } }

@media screen and (max-width: 600px) {
  .blog .posts-grid,
  .post-type-archive-cases .posts-grid {
    grid-template-columns: 1fr; } }

.constrict, .constrict-md, .constrict-sm, .constrict-xs, .constrict-lg {
  max-width: 83.75rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 3.125rem; }
  @media screen and (min-width: 768px) {
    .constrict, .constrict-md, .constrict-sm, .constrict-xs, .constrict-lg {
      padding-left: 1.875rem;
      padding-right: 1.875rem; } }

.constrict-md {
  max-width: 70rem;
  padding-bottom: 0; }

.constrict-sm {
  max-width: 62.5rem;
  padding-bottom: 0; }

.constrict-xs {
  max-width: 55.3125rem;
  padding-bottom: 0; }

.constrict-lg {
  max-width: 93.75rem;
  padding-bottom: 0; }

.post-type-archive-products .constrict, .post-type-archive-products .constrict-md, .post-type-archive-products .constrict-sm, .post-type-archive-products .constrict-xs, .post-type-archive-products .constrict-lg {
  padding-bottom: 0; }

.slick-arrow {
  cursor: pointer; }

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  body #content {
    flex: 1 0 auto; }
  body .site-header,
  body .footer {
    flex-shrink: 0; }

@media screen and (min-width: 768px) {
  .products-category-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 1; }
    .products-category-grid + .products-category-grid {
      margin-top: 3.125rem; }
    .products-category-grid__aside, .products-category-grid__products {
      flex: 1 1 calc(50% - 0.625rem); }
    .products-category-grid__aside {
      max-width: 22.5rem; }
    .products-category-grid__products {
      padding-left: 2.8%;
      max-width: calc(100% - 22.5rem); } }

.products-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3.125rem;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }

@media screen and (min-width: 600px) {
  .products-grid {
    grid-template-columns: repeat(2, 1fr); } }

@media screen and (min-width: 768px) {
  .products-grid {
    grid-template-columns: repeat(3, 1fr); }
    .products-grid.--md-col-2 {
      grid-template-columns: repeat(2, 1fr); } }

@media screen and (min-width: 1250px) {
  .products-grid.--lg-col-3 {
    grid-template-columns: repeat(3, 1fr); }
  .products-grid.--lg-col-4 {
    grid-template-columns: repeat(4, 1fr); } }

.post-type-products .detail {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 1.875rem; }
  .post-type-products .detail .image-selected {
    width: 100%;
    max-height: 25rem;
    margin-bottom: 1.25rem; }
    .post-type-products .detail .image-selected img {
      width: 100%;
      height: 25rem;
      object-fit: contain; }
  .post-type-products .detail .image-selector {
    display: flex;
    align-items: center; }
    .post-type-products .detail .image-selector .slick-arrow {
      margin: 0.625rem; }
    .post-type-products .detail .image-selector img {
      width: 5.25rem !important;
      height: 5.25rem;
      object-fit: contain;
      cursor: pointer;
      border: 0.0625rem solid transparent;
      padding: 0.3125rem; }
      .post-type-products .detail .image-selector img.active {
        border: 0.0625rem solid #0055f4; }
  .post-type-products .detail-content {
    display: grid; }
    .post-type-products .detail-content-social > p {
      margin: 0; }

.post-type-products .accordeon-title {
  display: grid;
  grid-template-columns: minmax(12.5rem, auto) 2fr minmax(1.875rem, auto);
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #bae5ff; }
  .post-type-products .accordeon-title svg {
    transition: 200ms; }
  .post-type-products .accordeon-title.active svg {
    transform: rotate(180deg); }
  .post-type-products .accordeon-title.active .accordeon-content {
    display: block; }

.post-type-products .accordeon-content {
  grid-column: 2;
  grid-row: 2;
  margin-right: 3.125rem;
  margin-bottom: 1.875rem;
  display: none; }

.post-type-products .accordeon-table .row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 1.3125rem; }
  .post-type-products .accordeon-table .row:nth-of-type(odd) {
    background: #f2f2f2; }

@media screen and (max-width: 990px) {
  .post-type-products .detail {
    display: block; }
    .post-type-products .detail-images {
      margin-bottom: 1.875rem; }
      .post-type-products .detail-images .image-selector {
        margin: 0; }
  .post-type-products .accordeon-title {
    grid-template-columns: 1fr 2fr minmax(1.875rem, auto); }
    .post-type-products .accordeon-title svg {
      margin-left: 0.625rem; }
  .post-type-products .accordeon-content {
    grid-column: span 3;
    margin-right: 0; } }

.single .constrict, .single .constrict-md, .single .constrict-sm, .single .constrict-xs, .single .constrict-lg {
  max-width: 69.375rem;
  margin: auto;
  padding-bottom: 0; }

.single__title {
  font-weight: 500; }

.single .share > p {
  margin: 0; }

.single .share-top {
  position: absolute;
  left: 1.875rem; }

.single .share-bottom {
  display: none;
  margin-bottom: 1.25rem; }

.single .post-nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr); }
  .single .post-nav .prev-post,
  .single .post-nav .next-post {
    color: #121f8a;
    font-weight: bold;
    font-size: 0.8125rem; }
    .single .post-nav .prev-post a,
    .single .post-nav .next-post a {
      text-transform: uppercase; }
      .single .post-nav .prev-post a svg,
      .single .post-nav .next-post a svg {
        width: 0.5rem;
        height: 0.5rem; }
  .single .post-nav .prev-post {
    grid-column: 1; }
    .single .post-nav .prev-post p {
      margin-left: 0.625rem; }
  .single .post-nav .next-post {
    grid-column: 2;
    justify-self: end;
    text-align: end; }
    .single .post-nav .next-post p {
      margin-right: 0.625rem; }

@media screen and (max-width: 1300px) {
  .single .share-top {
    display: none; }
  .single .share-bottom {
    display: block; } }
