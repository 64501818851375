.hdr-logo {
  margin: 0;
  height: 100%;

  img {
    display: block;
    height: 100%;
  }

  span {
    @include visually-hidden;
  }
}

.hdr-logo--lrg {
  img {
    width: 100%;
    max-width: rem(270);
    height: auto;
  }
}
