$dur: .2s;
$del: .2s;
$ease: ease;

.icn-menu {
  width: rem(30);
  height: rem(30);
  display: block;
  position: relative;

  &__inner {
    top: rem(14);
    transition: background-color 0s $ease calc(#{$dur} * 2);

    &,
    &::before,
    &::after {
      display: block;
      width: 100%;
      height: 3px;
      position: absolute;
      background-color: $main-blue;
    }

    &::before,
    &::after {
      content: '';
      transition: top $dur $ease calc(#{$dur} * 2), width $dur $ease, transform $dur $ease $del;
    }

    &::before {
      top: rem(-10);
    }

    &::after {
      top: rem(10);
      width: 50%;
    }
  }
}



.nav-toggle:hover {
  .icn-menu {
    &__inner::after {
      width: 100%;
    }
  }
}





.nav-toggle[aria-expanded="true"] {
  .icn-menu {
    &__inner {
      background-color: transparent;
      transition: background-color 0s $ease $del;

      &::before,
      &::after {
        width: 100%;
        top: 0;
        transition: top $dur $ease, width $dur $ease, transform $dur $ease $del;
      }

      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }
}
