.nav-toggle {
  @include size(rem(40));
  background-color: transparent;
  appearance: none;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  justify-content: center;
  color: $main-blue;
  position: relative;
}
