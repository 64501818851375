


@include above($tablet) {
  .products-category-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;

    & + & {
      margin-top: rem(50);
    }

    &__aside,
    &__products {
      flex: 1 1 calc(50% - #{rem(10)});
    }

    &__aside {
      max-width: rem(360);
    }

    &__products {
      padding-left: 2.8%;
      max-width: calc(100% - #{rem(360)});
    }
  }
}
