h1,
h2,
h3,
h4 {
    color: $main-blue;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    margin: 0;

    &.center {
        text-align: center;
    }

    &.spacing {
        margin-top: rem(22);
        margin-bottom: rem(50);
    }

    &.underline {
        position: relative;
        display: inline-block;
        padding-bottom: rem(25);
        &:after {
            position: absolute;
            content: "";
            height: rem(4);
            width: rem(69);
            border-radius: rem(4);
            background: linear-gradient(90deg, $main-blue, $mid-blue);
            left: 0;
            bottom: 0;
        }

        &-center {
            text-align: center;
            display: flex;
            justify-content: center;
            &:after {
                left: calc(50% - (#{rem(69)} / 2));
            }
        }
    }
}

h1 {
    font-size: rem(50);
    line-height: lh(60, 50);
}

h2 {
    font-size: rem(36);
}

h3 {
    font-size: rem(24);
    line-height: lh(34, 24);
}

h4 {
    font-size: rem(18);
    font-weight: 500;
}

@include below($desktop-md) {
    h1 {
        font-size: rem(43);
    }
    h2 {
        font-size: rem(30);
    }
    h3 {
        font-size: rem(18);
    }
    h4 {
        font-size: rem(16);
    }
}
