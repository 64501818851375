
.text-input,
.wpcf7-text,
textarea {
  padding-left: rem(18);
  padding-right: rem(18);
  border-radius: rem(15);
  font-size: rem(14);
  border: 1px solid $main-blue;
  width: 100%;
  height: rem(45);
  outline: none;

  &[aria-invalid="true"] {
    border-color: $red;
  }
}

textarea {
  padding: rem(18);
  height: rem(210);
}

.select {
    outline: none;
    -webkit-appearance: none;
    border-radius: 0;
    border: none;
    background: none;
    color: $main-blue;
    padding-right: rem(30);

    &_wrapper {
        position: relative;
        font-size: rem(16);
        border-bottom: rem(1) solid $main-blue;
        width: fit-content;
        z-index: 1;
        padding-top: 0;
        padding-bottom: .4em;

        &::before {
            content: "";
            display: block;
            position: absolute;
            right: rem(10);
            top: 25%;
            height: rem(10);
            width: rem(2);
            background: $main-blue;
            transform: rotate(45deg);
            z-index: -1;
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            right: rem(16);
            top: 25%;
            height: rem(10);
            width: rem(2);
            background: $main-blue;
            transform: rotate(-45deg);
            z-index: -1;
        }
    }
}
