.subscribe-block {
  margin-top: rem(50);

  &__content {
    background-color: $light-blue;
    border-radius: 0 rem(50) 0 0;
    padding: rem(30) rem(20);
    padding-left: 0;
    position: relative;
    max-width: rem(1100);
    margin-right: rem(-20);

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      display: block;
      width: 100vw;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: inherit;
      border-radius: inherit;
    }

    p {
      margin: 0;
    }
  }

  &__intro {
    margin: 0 0 rem(20) 0;
  }

  &__logo {
    display: none;
  }
}

@include above($tablet) {
  .subscribe-block {
    &__inner {
      display: flex;
    }


    &__content {
      flex: 1 1 auto;
      margin-right: unset;
      padding-right: rem(30);
    }


    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: rem(30);
      flex: 0 1 rem(270);

      .hdr-logo {
        height: auto;
      }

      img {
        max-width: rem(270);
      }
    }

    &__form {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@include above($desktop-sm) {
  .subscribe-block {
    &__content {
      min-height: rem(170);
      display: flex;
      align-items: center;
      padding-right: 0;
    }

    &__intro {
      margin: 0;
    }

    &__form {
      padding: 0 rem(30);
      flex: 1;

      .wpcf7 {
        max-width: rem(480)
      }
    }
  }
}

@include above($desktop-md) {
  .subscribe-block {
    &__logo {
      flex-basis: rem(360);
    }
  }
}
