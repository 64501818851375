.mobile-menu {
  position: fixed;
  top: rem(60);
  left: 0;
  right: 0;
  z-index: 9999;

  &__inner {
    height: calc(100vh - #{rem(60)});
    position: absolute;
    top: 0;
    transform: translateX(100%);
    max-width: rem(400);
    width: 100%;
    overflow-y: auto;
    right: 0;
    background-color: $white;
    transition: transform .3s ease;
    padding: rem(30);
  }

  &__primary {

    .btn {
      margin-top: rem(30);
      width: 100%;
    }
  }

  &.show {
    .mobile-menu__inner {
      transform: translateX(0);
    }
  }

}

.admin-bar {
  .mobile-menu {
    top: rem(60 + 46);

    @include above(783px) {
      top: rem(60 + 32);
    }

    &__inner {
      height: calc(100vh - #{rem(60 + 46)});

      @include above(783px) {
        height: calc(100vh - #{rem(60 + 32)});
      }
    }
  }
}

.menu-open {
  overflow-y: hidden;
}

@include above($tablet) {
  .menu-open {
    height: 100vh;
    padding-right: 15px;
  }
}


@include above($desktop) {
  .mobile-menu {
    display: none;
  }
}
