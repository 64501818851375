.hero {
    position: relative;
    padding-top: rem(42);
    padding-bottom: rem(79);
    margin-bottom: rem(30);

    & > .background {
        position: absolute;
        overflow: hidden;
        background: linear-gradient(90deg, $main-blue, $mid-blue);
        width: 100%;
        height: 100%;
        z-index: -1;
        border-radius: 0 0 rem(50) rem(50);
        top: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            z-index: -1;
            mix-blend-mode: darken;
        }
    }

    .constrict {
        height: 100%;
        padding-bottom: 0;
        padding-top: 0;
    }

    &_wrapper {
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .title {
            color: white;

            &::after {
                background: white;
            }
        }
    }
}
