#cookie-notice {
	position: fixed;
	min-width: 100%;
	height: auto;
	z-index: 100000;
	font-size: 13px;
	letter-spacing: 0;
	line-height: 20px;
	left: 0;
	text-align: center;
	/* border-top: 2px solid #fbb03b; */
	font-weight: normal;
	font-family: -apple-system,BlinkMacSystemFont,Arial,Roboto,"Helvetica Neue",sans-serif;
}

#cookie-notice,
#cookie-notice * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

#cookie-notice.cn-animated {
	-webkit-animation-duration: 0.5s !important;
	animation-duration: 0.5s !important;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

#cookie-notice.cn-animated.cn-effect-none {
	-webkit-animation-duration: 0.001s !important;
	animation-duration: 0.001s !important;
}

#cookie-notice .cookie-notice-container {
	display: block;
}

#cookie-notice.cookie-notice-hidden .cookie-notice-container {
	display: none;
}

#cookie-notice .cookie-revoke-container {
	display: block;
}

#cookie-notice.cookie-revoke-hidden .cookie-revoke-container {
	display: none;
}

.cn-position-top {
	top: 0;
}

.cn-position-bottom {
	bottom: 0;
}

.cookie-notice-container {
	padding: 15px 30px;
	text-align: center;
	width: 100%;
	z-index: 2;
}

.cookie-revoke-container {
	padding: 15px 30px;
	width: 100%;
	z-index: 1;
}

.cn-close-icon {
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -10px;
	width: 15px;
	height: 15px;
	opacity: 0.5;
	padding: 10px;
	outline: none;
}

.cn-close-icon:hover {
	opacity: 1;
}
.cn-close-icon:before,
.cn-close-icon:after {
	position: absolute;
	content: ' ';
	height: 15px;
	width: 2px;
	top: 3px;
	background-color: #fff;
}
.cn-close-icon:before {
	transform: rotate(45deg);
}
.cn-close-icon:after {
	transform: rotate(-45deg);
}

#cookie-notice .cn-revoke-cookie {
	margin: 0;
}

#cookie-notice .cn-button {
	margin: 0 0 0 10px;
	border: none;
}

.cn-button {
	font-family: -apple-system,BlinkMacSystemFont,Arial,Roboto,"Helvetica Neue",sans-serif;
	font-weight: normal;
	font-size: 13px;
	letter-spacing: 0.25px;
	line-height: 20px;
	margin: 0;
	padding: 0;
	text-align: center;
	text-transform: none;
	display: inline-block;
	cursor: pointer;
	touch-action: manipulation;
	white-space: nowrap;
	outline: none;
	box-shadow: none;
	text-shadow: none;
	border: none;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.cn-button:hover {
	box-shadow: inset 0 0 0 99999px rgba(0,0,0,0.05);
	text-decoration: none;
}

.cn-button.wp-default,
.cn-button.bootstrap {
	text-decoration: none;
	padding: 8.5px 10px;
	line-height: 1;
}

.cn-button.wp-default {
	color: #fff;
	background: #fbb03b;
}

.cn-button.wp-default:hover {
}

.cn-button.bootstrap {
	color: #fff;
	background: #20C19E;
}

.cn-button.bootstrap:hover {
}

.cn-text-container {
	margin: 0 0 6px 0;
}

.cn-text-container,
.cn-buttons-container {
	display: inline-block;
}

#cookie-notice.cookie-notice-visible.cn-effect-none,
#cookie-notice.cookie-revoke-visible.cn-effect-none {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

#cookie-notice.cn-effect-none {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

#cookie-notice.cookie-notice-visible.cn-effect-fade,
#cookie-notice.cookie-revoke-visible.cn-effect-fade {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

#cookie-notice.cn-effect-fade {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

#cookie-notice.cookie-notice-visible.cn-effect-slide,
#cookie-notice.cookie-revoke-visible.cn-effect-slide{
	-webkit-animation-name: slideInUp;
	animation-name: slideInUp;
}

#cookie-notice.cn-effect-slide {
	-webkit-animation-name: slideOutDown;
	animation-name: slideOutDown;
}

#cookie-notice.cookie-notice-visible.cn-position-top.cn-effect-slide,
#cookie-notice.cookie-revoke-visible.cn-position-top.cn-effect-slide {
	-webkit-animation-name: slideInDown;
	animation-name: slideInDown;
}

#cookie-notice.cn-position-top.cn-effect-slide {
	-webkit-animation-name: slideOutUp;
	animation-name: slideOutUp;
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes slideInUp {
	from {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInUp {
	from {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes slideOutDown {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
}

@keyframes slideOutDown {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
}

@-webkit-keyframes slideInDown {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInDown {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes slideOutUp {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
}

@keyframes slideOutUp {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
}

@media all and (max-width: 900px) {
	.cookie-notice-container #cn-notice-text {
		display: block;
	}
	.cookie-notice-container #cn-notice-buttons {
		display: block;
	}
	#cookie-notice .cn-button {
		margin: 0 5px 5px 5px;
	}
}

@media all and (max-width: 480px) {
	.cookie-notice-container,
	.cookie-revoke-container {
		padding: 15px 25px;
	}
}
