body {
    font-family: "Nunito", sans-serif;
}

p {
    font-size: rem(15);
    line-height: lh(30, 15);

    &.body-small {
        font-size: rem(12);
    }

    &.body-big {
        font-size: rem(20);
    }
}

a {
    font-family: "Nunito", sans-serif;
}

.btn {
    a {
        font-family: "DM Sans", sans-serif;
        letter-spacing: rem(1);
    }
}
