.product_advantages {
    border-radius: 0 0 rem(50) rem(50);
    position: relative;
    padding-top: rem(45);
    margin-bottom: rem(30);

    .title {
      color: $white;
    }

    & > .background {
        position: absolute;
        overflow: hidden;
        background: linear-gradient($main-blue, $mid-blue);
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-radius: 0 0 rem(50) rem(50);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            opacity: 0.1;
            mix-blend-mode: multiply;
        }
    }

    &.home {
        margin-top: rem(-140);
        padding-top: rem(140);
    }

    &_wrapper {
        color: $white;
        text-align: center;

        .header {
            max-width: rem(600);
            margin: auto;
        }

        h3,
        a {
            color: $white;
        }

        .read-more {
            text-transform: uppercase;
            font-weight: bold;
            font-size: rem(13);
        }

        .advantages {
            display: grid;
            grid-gap: rem(30);
            grid-auto-flow: column;

            .slick-list {
                margin-top: rem(38);
                margin-bottom: rem(29);
            }

            .advantage {
                text-align: left;
                padding: 0 rem(15);

                .title {
                    display: flex;
                    align-items: center;
                    height: rem(69);

                    img {
                        width: rem(69);
                        height: auto;
                        max-height: rem(69);
                        margin-right: rem(10);
                    }
                }
            }
        }
    }
}

@include below($desktop-sm) {
    .product_advantages {
        &_wrapper {
            .advantages {
                display: flex;
                align-items: center;

                .slick-arrow {
                    svg {
                        path {
                            fill: white;
                        }
                    }
                }
            }
        }
    }
}
